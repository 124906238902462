var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-date-picker',{attrs:{"type":_vm.type,"value-format":_vm.format,"format":_vm.format,"range-separator":"至","start-placeholder":"开始时间","end-placeholder":"结束时间","unlink-panels":"","size":_vm.size,"picker-options":{
    disabledDate: (time) => {
      if (_vm.timeLimit) {
        return (
          time.getTime() <
          new Date(this.timeLimit).getTime() - 1 * 24 * 60 * 60 * 1000
        );
      }
    },
  }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})
}
var staticRenderFns = []

export { render, staticRenderFns }