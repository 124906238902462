<template>
    <el-cascader :options="options"
                 v-bind="$attrs"
                 v-model="model"
                 :style="$vnode.data.style"
                 :class="$vnode.data.class" style="width:100%" :size="size"
                 filterable
                 :props="{ checkStrictly: checkStrictly, multiple: multiple, emitPath: false }"></el-cascader>
</template>

<script>
import {getDictType, initDictByType} from "@/util/dict"
import {v4} from 'uuid';

export default {
    name: "XDictCascader",
    data() {
        return {
            dict: getDictType(this.dictType),
            uuid: v4(),
        }
    },
    computed: {
        options() {
            let os = this.dict.map(o => {
                return {
                    value: o.value.code,
                    label: o.value.name,
                    rcode: o.value.rcode
                }
            });
            let cascade = {};
            os.forEach(o => {
                cascade[o.value] = o;
            })
            let result = [];
            os.forEach(o => {
                let parent = cascade[o.rcode];
                if (parent) {
                    if (!parent.children) {
                        parent.children = []
                    }
                    parent.children.push(o);
                } else {
                    result.push(o);
                }
            })
            return result;
        },
        model: {
            get() {//TODO 处理多选的情况
                if(this.multiple) {
                    if(!this.value) return [];
                    return this.value.split(this.separator);
                }
                return this.value;
            },
            set(newVal, oldVal) {
                let value= this.multiple && Array.isArray(newVal) ? newVal.join(this.separator): newVal;
                this.$emit('input',value);
            }
        }
    },
    methods: {
        getDictLabel(dictKey) {
            const dict = this.dict.find(dict => dict.key === dictKey);
            return dict && dict.value;
        }
    },
    created() {
        initDictByType(this.dictType);
        this.selectedType = typeof this.selected;
    },
    props: {
        dictType: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: "small"
        },
        value: String,
        /*
        可在父组件直接传el-cascader属性进行赋值
        disabled:{
            type:Boolean,
            default: false
        },
        placeholder:{
            type: String,
            default: "请选择"
        },*/
        multiple: {
            type:Boolean,
            default: false
        },
        checkStrictly: {
            type:Boolean,
            default: true
        },
        separator: {
            type: String,
            default: ","
        }
    }
}
</script>

<style scoped>

</style>
