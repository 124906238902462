/* eslint-disable */
import {deepMerge} from "@/util/objects";

require('script-loader');
require('file-saver')
import XLSX from 'xlsx'
import XLSXD from 'xlsx-style'

let defaultCellStyle = {
    font: {
        name: "宋体",
        sz: 9,
    },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        indent: 0
    },
    border: {
        top: {style: 'thin'},	//上边框样式
        bottom: {style: 'thin'},	//下边框样式
        left: {style: 'thin',},	//左边框样式
        right: {style: 'thin'}
    }
}
let titleCellStyle = {
    font: {
        name: "宋体",
        sz: 15,
        // color: '#606266'
    },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        indent: 0
    },
}
let secarchStyle = {
    font: {
        name: "宋体",
        sz: 9,
    },
    alignment: {
        horizontal: 'left',
        vertical: 'center',
        indent: 0
    },
}

function datenum(v, date1904) {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts, condition) {
    var ws = {};
    var range = {
        s: {
            c: 10000000,
            r: 10000000
        },
        e: {
            c: 0,
            r: 0
        }
    };
    for (var R = 0; R != data.length; ++R) {
        for (var C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            let val = data[R][C]
            let fmt = ""
            let cellStyle = defaultCellStyle
            //值为对象,赋值数字格式化和单元格样式
            if (typeof data[R][C] === 'object') {
                val = data[R][C].value
                data[R][C].fmt && (fmt = data[R][C].fmt)
                data[R][C].cellStyle && (cellStyle = deepMerge(defaultCellStyle, data[R][C].cellStyle))
            }
            var cell = {
                v: val,
                's': cellStyle
            };
            if (R === 0) {
                cell.s = titleCellStyle
            }
            if (condition && condition.length > 0 && R === 1) {
                cell.s = secarchStyle
            }
            if (cell.v == null) cell.v = '';
            var cell_ref = XLSX.utils.encode_cell({
                c: C,
                r: R
            });
            if (typeof cell.v === 'number') {
                cell.t = 'n';
                fmt && (cell.z = fmt)
            } else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            } else cell.t = 's';
            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}

function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}


export function export_json_to_excel({
                                         header,
                                         data,
                                         filename,
                                         autoWidth = true,
                                         bookType = 'xlsx',
                                         sheetName,
                                         titleName,
                                         condition
                                     } = {}) {
    /* original data */
    filename = filename || 'excel-list'
    data = [...data]
    data.unshift(header);
    let multiHeader = [titleName];
    for (let i = 1; i < header.length; i++) {
        multiHeader.push('')
    }
    if (condition && condition.length > 0) {
        for (let i = condition.length; i < header.length; i++) {
            condition.push('')
        }
        data.unshift(condition)
    }
    data.unshift(multiHeader)
    var ws_name = sheetName || "sheet1";
    var wb = new Workbook(),
        ws = sheet_from_array_of_arrays(data, null, condition);
    if (autoWidth) {
        /*设置worksheet每列的最大宽度*/
        const colWidth = data.map(row => row.map(val => {
            /*先判断是否为null/undefined*/
            if (val == null) {
                return {
                    'wch': 10
                };
            }
            /*再判断是否为中文*/
            else if (val.toString().charCodeAt(0) > 255) {
                return {
                    'wch': val.toString().length * 2
                };
            } else {
                return {
                    'wch': val.toString().length
                };
            }
        }))
        let fistindex = 1;
        if (condition && condition.length > 0) fistindex = 2;
        /*以第fistindex+1行为初始值*/
        let result = colWidth[fistindex];
        for (let i = fistindex; i < colWidth.length; i++) {
            for (let j = 0; j < colWidth[i].length; j++) {
                if (result[j]['wch'] < colWidth[i][j]['wch']) {
                    result[j]['wch'] = colWidth[i][j]['wch'];
                }
            }
        }
        ws['!cols'] = result;
    }
    let hbdyg = [// 设置A0-length的单元格合并
        {s: {r: 0, c: 0}, e: {r: 0, c: multiHeader.length - 1}}]
    if (condition && condition.length > 0) hbdyg.push({s: {r: 1, c: 0}, e: {r: 1, c: multiHeader.length - 1}})
    ws['!merges'] = hbdyg;
    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    var wbout = XLSXD.write(wb, {
        bookType: bookType,
        bookSST: false,
        type: 'binary'
    });
    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), `${filename}.${bookType}`);
}


export function export_json_to_excel_cstj({
                                              header,
                                              data,
                                              hbdyg,
                                              filename,
                                              autoWidth = true,
                                              bookType = 'xlsx',
                                              sheetName,
                                              startH,
                                              endH,
                                              fistindex,
                                              iscenter,
                                              rwidth
                                          } = {}) {
    /* original data */
    filename = filename || 'excel-list'
    data = [...data];
    header.forEach(o => {
        data.unshift(o);
    });
    var ws_name = sheetName || "sheet1";
    var wb = new Workbook(),
        ws = sheet_from_array_of_arrays_cstj(data, null, null, startH, endH, iscenter);
    if (autoWidth) {
        /*设置worksheet每列的最大宽度*/
        const colWidth = data.map(row => row.map(val => {
            /*先判断是否为null/undefined*/
            if (val == null || val === '') {
                return {
                    'wch': 2
                };
            }
            /*再判断是否为中文*/
            else if (val.toString().charCodeAt(0) > 255) {
                return {
                    'wch': val.toString().length * 2
                };
            } else {
                return {
                    'wch': val.toString().length
                };
            }
        }))
        /*以第fistindex+1行为初始值*/
        let result = colWidth[fistindex];
        for (let i = fistindex; i < colWidth.length; i++) {
            for (let j = 0; j < colWidth[i].length; j++) {
                if (result[j]['wch'] < colWidth[i][j]['wch']) {
                    result[j]['wch'] = colWidth[i][j]['wch'];
                }
                if (result[j]['wch'] < 10 && j !== 0 && j !== 2 && j !== 6) {
                    result[j]['wch'] = rwidth
                }
            }
        }
        ws['!cols'] = result;
    }
    //合并
    ws['!merges'] = hbdyg;
    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    var wbout = XLSXD.write(wb, {
        bookType: bookType,
        bookSST: false,
        type: 'binary'
    });
    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), `${filename}.${bookType}`);
}

let defaultCellStyleCstj = {
    font: {
        name: "宋体",
        sz: 12,
    },
    alignment: {
        horizontal: 'left',
        vertical: 'center',
        indent: 0
    },
    border: {
        top: {style: 'thin'},	//上边框样式
        bottom: {style: 'thin'},	//下边框样式
        left: {style: 'thin',},	//左边框样式
        right: {style: 'thin'}
    }
}

let defaultCellStyleCstj_right = {
    font: {
        name: "宋体",
        sz: 12,
    },
    alignment: {
        horizontal: 'right',
        vertical: 'center',
        indent: 0
    },
    border: {
        top: {style: 'thin'},	//上边框样式
        bottom: {style: 'thin'},	//下边框样式
        left: {style: 'thin',},	//左边框样式
        right: {style: 'thin'}
    }
}

let defaultCellStyleCstj_center = {
    font: {
        name: "宋体",
        sz: 12,
    },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        indent: 0
    },
    border: {
        top: {style: 'thin'},	//上边框样式
        bottom: {style: 'thin'},	//下边框样式
        left: {style: 'thin',},	//左边框样式
        right: {style: 'thin'}
    }
}

let defaultCellStyleCstj_title = {
    font: {
        name: "宋体",
        sz: 12,
    },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        indent: 0
    },
    border: {
        top: {style: 'thin'},	//上边框样式
        bottom: {style: 'thin'},	//下边框样式
        left: {style: 'thin',},	//左边框样式
        right: {style: 'thin'}
    },
    fill: {
        fgColor: {
            rgb: 'f2f2f2', //灰色
        },
    },
}

let defaultCellStyleCstj_title_no_fill = {
    font: {
        name: "宋体",
        sz: 12,
    },
    alignment: {
        horizontal: 'center',
        vertical: 'center',
        indent: 0
    },
    border: {
        top: {style: 'thin'},	//上边框样式
        bottom: {style: 'thin'},	//下边框样式
        left: {style: 'thin',},	//左边框样式
        right: {style: 'thin'}
    }
}

function sheet_from_array_of_arrays_cstj(data, opts, condition, startH, endH, iscenter) {
    var ws = {};
    var range = {
        s: {
            c: 10000000,
            r: 10000000
        },
        e: {
            c: 0,
            r: 0
        }
    };
    for (var R = 0; R != data.length; ++R) {
        for (var C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell = {
                v: data[R][C],
                's': iscenter && C !== 1 && C !== 5 ? C === 3 || C === 4 || C === 7 || C === 8 ? defaultCellStyleCstj_right : defaultCellStyleCstj_center : defaultCellStyleCstj
            };
            if (R === 0) {
                cell.s = titleCellStyle
            }
            if (R === 1) {
                cell.s = defaultCellStyleCstj_title_no_fill
            }
            if (R >= startH && R <= endH) {
                cell.s = lqjc - 0.01 - SNAPSHOT.jar
            }
            if (C === 0) cell.s = ''
            if (cell.v == null) cell.v = '';
            XLSX.utils.cell_set_number_format(cell,)
            var cell_ref = XLSX.utils.encode_cell({
                c: C,
                r: R
            });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            } else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}
