<template>
    <el-popover>
        <div :style="{width: width + 'px'}">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText" style="width:180px"></el-input>
            <el-button type="success" size="small" icon="el-icon-check" @click="handleConfirm"
                       style="margin-left: 10px"></el-button>
            <el-button type="primary" size="small" icon="el-icon-brush" @click="handleClear"></el-button>
        </div>
        <el-tree :style="{maxHeight:treeHeight+'px'}" style="overflow:auto" ref="tree"
                 :data="treeData"
                 :props="{children: 'subNodes',label: 'name', disabled:disabledFunction}"
                 :node-key="nodeKey"
                 :default-expanded-keys="expanded"
                 :filter-node-method="filterNode"
                 :check-strictly="true"
                 show-checkbox
                 @check-change="handleCheckChange">
            <span slot-scope="{ node, data }" :class="data.icon">
                {{ data.name }}<span v-if="!node.isLeaf"> ({{ data.subNodes.length }}) </span>
            </span>
        </el-tree>
        <el-input slot="reference" :type="multiple ? 'textarea' : 'text'" v-model="name" size="small" ref="toggle"/>
    </el-popover>
</template>

<script>
import {isNotEmpty} from "@/util/objects"

export default {
    name: "XTreeSelector",
    data() {
        return {
            filterText: '',
            treeData: [],
            disabledFunction: (data, node) => {
                if (this.disabledFunc) {
                    return this.disabledFunc(data, node);
                } else {
                    return false
                }
            }
        };
    },
    computed: {
        expanded() {
            if (!this.value) return []
            let checks = this.array ? this.value : this.value.split(",");
            this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys(checks);
            });
            return checks;
        }
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    beforeMount() {
        this.treeService().then((response) => {
            this.treeData = response.data;
        })
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        _getFullName(node, name) {
            if (node.level > 1) {
                let n = node.parent.data.name + this.fullSeparator + name;
                return this._getFullName(node.parent, n);
            } else {
                return name;
            }
        },
        _select() {
            let checks = this.$refs.tree.getCheckedNodes();
            let keys = [];
            let name = [];
            if (isNotEmpty(checks)) {
                let node = this.$refs.tree.getNode(checks[0][this.nodeKey]);
                keys = checks.map(obj => obj[this.nodeKey]);
                name = checks.map((obj) => {
                    if (this.fullName) {
                        let node = this.$refs.tree.getNode(obj[this.nodeKey]);
                        return this._getFullName(node, node.data.name);
                    } else {
                        return obj.name
                    }
                });
            }
            this.$emit("input", this.array ? keys : keys.join(","));
            this.$emit("update:name", this.array ? name : name.join(","));
            this.$emit("selected", checks);
        },
        handleCheckChange(data, checked, node) {
            //处理单选选中的情况
            if (!this.multiple && checked) {
                this.$refs.tree.setCheckedKeys([data[this.nodeKey]]);
            }
            this._select();
        },
        handleConfirm() {
            this.$refs.toggle.$el.click();
        },
        handleClear() {
            this.$refs.tree.setCheckedKeys([]);
            this._select();
        },
    },
    props: {
        width: {
            type: [Number, String],
            default: 300
        },
        treeHeight: {
            type: [Number, String],
            default: 500
        },
        value: {
            type: [String, Array],
            required: true
        },
        name: {
            type: [String, Array],
        },
        multiple: {
            type: Boolean,
            default: false
        },
        nodeKey: {
            type: String,
            default: "id"
        },
        array: {
            type: Boolean,
            default: false
        },
        treeService: {
            type: Function,
            required: true
        },
        fullName: {
            type: Boolean,
            default: false
        },
        fullSeparator: {
            type: String,
            default: " / "
        },
        disabledFunc: {
            type: Function
        }
    }
};
</script>

<style scoped>
.tree {
    overflow: auto;
    max-height: 500px;
}
</style>
