/**
 * localStorage
 * @调用：_local.set('access_token', '123456', 5000);
 * @调用：_local.get('access_token'); 1000 * 60 * 60 * 24 * 30
 */
const LOCAL_STORAGE = {
    //存储,可设置过期时间
    set(key, value, expires) {
        let params = {key, value, expires};
        if (expires) {
            // 记录何时将值存入缓存，毫秒级
            var data = Object.assign(params, {startTime: new Date().getTime()});
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            let toString = Object.prototype.toString;
            if (toString.call(value) === '[object Object]' || toString.call(value) === '[object Array]') {
                value = JSON.stringify(value);
            }
            localStorage.setItem(key, value);
        }
    },
    //取出
    get(key) {
        let item = localStorage.getItem(key);
        // 先将拿到的试着进行json转为对象的形式
        if (isJSON(item)) {
            item = JSON.parse(item);
        }
        // 如果有startTime的值，说明设置了失效时间
        if (item && item.startTime) {
            let date = new Date().getTime();
            // 如果大于就是过期了，如果小于或等于就还没过期
            if (date - item.startTime > item.expires) {
                localStorage.removeItem(name);
                return null;
            } else {
                return item.value;
            }
        } else {
            return item;
        }
    },
    // 删除
    remove(key) {
        localStorage.removeItem(key);
    },
    //所有的键
    keys() {
        let keys = [];
        for (let i = 0; i < localStorage.length; i++) {
            keys.push(localStorage.key(i));
        }
        return keys;
    },
    // 清除全部
    clear() {
        localStorage.clear();
    }
}

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
}

/**
 * sessionStorage
 */
const SESSION_STORAGE = {
    get: function (key) {
        var data = sessionStorage[key];
        if (!data || data === "null") {
            return null;
        }
        return JSON.parse(data).value;
    },
    set: function (key, value) {
        var data = {
            value: value
        }
        sessionStorage[key] = JSON.stringify(data);
    },
    // 删除
    remove(key) {
        sessionStorage.removeItem(key);
    },
    // 清除全部
    clear() {
        sessionStorage.clear();
    }
}
export {LOCAL_STORAGE, SESSION_STORAGE}
