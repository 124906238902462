const bjggs = {
    APP_NAME: process.env.VUE_APP_NAME,
    BASE_API: BASE.IS_DOCKER ? BASE.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API,
    UPLOAD_URI: process.env.VUE_APP_UPLOAD_URI,
    UPLOAD_PRIVATE_URI: process.env.VUE_APP_UPLOAD_PRIVATE_URI,
    DOWNLOAD_URI: BASE.IS_DOCKER ? BASE.VUE_APP_BASE_API : process.env.VUE_APP_DOWNLOAD_URI,
    DOWNLOAD_PRIVATE_URI: process.env.VUE_APP_DOWNLOAD_PRIVATE_URI,
    WS_API: BASE.IS_DOCKER ? BASE.VUE_APP_BASE_API + '/messager' : process.env.VUE_APP_WS_API,
};

export default bjggs;
