const getters = {
    tags: state => state.viewTag.tags,
    user: state => state.user.user,
    token: state => state.user.user.token,
    permission_routes: state => state.permission.routes,
    subsystemRoutes: state => state.permission.subsystemRoutes,
    userInfo: state => state.user.userInfo,
    customer: state => state.customer.customer
};
export default getters
