<template>
  <el-date-picker
    v-model="model"
    :type="type"
    :value-format="format"
    :format="format"
    range-separator="至"
    start-placeholder="开始时间"
    end-placeholder="结束时间"
    unlink-panels
    :size="size"
    :picker-options="{
      disabledDate: (time) => {
        if (timeLimit) {
          return (
            time.getTime() <
            new Date(this.timeLimit).getTime() - 1 * 24 * 60 * 60 * 1000
          );
        }
      },
    }"
  >
  </el-date-picker>
</template>
<script>
export default {
  name: "XDateBetween",
  data() {
    return {
      timeLimit: "",
    };
  },
  watch: {
    limitRangeDate(curVal, oldVal) {
      if (curVal) {
        this.timeLimit = curVal;
        this.$emit("update:start", "");
        this.$emit("update:end", "");
      }
    },
  },
  computed: {
    model: {
      get() {
        return [this.start, this.end];
      },
      set(newVal, oldVal) {
        if (Array.isArray(newVal) && newVal.length === 2) {
          this.$emit("update:start", newVal[0]);
          this.$emit("update:end", newVal[1]);
        } else {
          this.$emit("update:start", "");
          this.$emit("update:end", "");
        }
      },
    },
    format() {
      switch (this.type) {
        case "datetimerange":
          return "yyyy-MM-dd HH:mm:ss";
        case "monthrange":
          return "yyyy-MM";
        case "daterange":
        default:
          return "yyyy-MM-dd";
      }
    },
  },
  props: {
    start: String,
    end: String,
    type: {
      type: String, // datetimerange daterange monthrange
      default: "daterange",
    },
    size: {
      type: String,
      default: "small",
    },
    limitRangeDate: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
