<template>
    <el-switch :disabled="switchDisabled" v-model="model" v-on="$listeners" :style="$vnode.data.style" :class="$vnode.data.class" :size="size"
               :active-text="activeText" :inactive-text="inactiveText"
               inactive-value="false" active-value="true"/>
</template>

<script>
    import {getDictType, initDictByType} from "@/util/dict"

    export default {
        name: "XSwitch",
        data() {
            return {
                activeText: "",
                inactiveText: "",
            }
        },
        created() {
            initDictByType(this.dictType).then(this.renderNames);
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                }
            }
        },
        props: {
            dictType: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: "small"
            },
            value: {
                type: String,
                required: true
            },
            switchDisabled:{
                type:Boolean,
                default: false
            }
        },
        methods: {
            renderNames() {
                let dict = getDictType(this.dictType);
                dict.forEach((obj) => {
                    if (obj.key === 'true') {
                        this.activeText = obj.value;
                    }
                    if (obj.key === 'false') {
                        this.inactiveText = obj.value;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
