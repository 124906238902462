<template>
    <el-popover :placement="placement" ref="popover" @input="visible = $event" style="width: 100%">
        <component :is="xselector"
                   :style="{width:width, height:height}"
                   @selected="handleSelector"
                   @table-resize="handleTableResize"
                   :selection="selection"
                   v-if="visible"
                   :visible="visible"
                   :code-key="codeKey"
                   :value="model"
                   :param="param">
        </component>
        <div slot="reference" ref="toggle">
            <slot>
                <el-button size="small">请选择</el-button>
            </slot>
        </div>
    </el-popover>
</template>
<script>
import Vue from 'vue'

export default {
    name: "XSelector",
    data() {
        return {
            xselector: "div",
            visible: false
        }
    },
    mounted() {
        let selector = this.selector;
        Vue.component(selector, (resolve) => require([`@/view/selector/${selector}`], resolve));
        this.xselector = selector;
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val)
            }
        }
    },
    watch: {
        selector(n, old) {
            Vue.component(n, (resolve) => require([`@/view/selector/${n}`], resolve));
            this.$nextTick(() => {
                    this.xselector = n;
                }
            );
        }
    },
    methods: {
        handleSelector(arr) {
            let vs = arr.map(obj => obj[this.codeKey]);
            this.model = this.array ? vs : vs.join(",");
            this.setName(arr);
            this.$emit("selected", arr);
            this.$refs.toggle.click();
        },
        setName(arr) {
            if (arr && arr.length > 0 && arr[0][this.nameKey]) {
                let vs = arr.map(obj => obj[this.nameKey]);
                this.$emit("update:name", this.array ? vs : vs.join(","));
            } else {
                this.$emit("update:name", this.array ? [] : "");
            }
        },
        handleTableResize() {
            this.$refs.popover.updatePopper();
        }
    },
    props: {
        value: {
            type: [String, Array],
        },
        selector: {
            type: String,
            required: true
        },
        codeKey: {
            type: String,
            default: "id"
        },
        nameKey: {
            type: String,
            default: "name"
        },
        selection: {
            type: Boolean,
            default: false
        },
        array: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        width: {
            type: String,
            default: '800px'
        },
        height: {
            type: String,
            default: '700px'
        },
        param: {}
    }
}
</script>

<style scoped>

</style>
