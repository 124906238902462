<template>
    <el-select v-model="model"
               v-bind="$attrs"
               v-on="$listeners"
               :style="$vnode.data.style"
               :class="$vnode.data.class"
               :multiple="multiple"
               :size="size"
               :clearable="clearable"
               :placeholder="placeholder"
               style="width:100%">
        <el-option v-for="d in dict" :key="uuid + d.key" :label="d.value" :value="d.key"></el-option>
    </el-select>
</template>

<script>
import {getDictType, initDictByType} from "@/util/dict"
import {v4} from 'uuid';

export default {
    name: "XSelectorOne",
    model: {
        prop: "selected",
        event: "selected"
    },
    data() {
        return {
            dict: getDictType(this.dictType),
            uuid: v4()
        }
    },
    computed: {
        model: {
            get() {
                if (this.multiple) {
                    if (this.selectedType === 'string') {
                        if (!this.selected) return [];
                        return this.selected.split(",");
                    }
                }
                return this.selected;
            },
            set(value) {
                let selected = this.multiple && this.selectedType === 'string' && Array.isArray(value) ? value.join(",") : value;
                this.$emit('selected', selected);

                const selectedLabel = Array.isArray(value) && value.map(key => this.getDictLabel(key)).join(',') || this.getDictLabel(value);
                this.$emit('update:label', selectedLabel || '');
            }
        }
    },
    methods: {
        getDictLabel(dictKey) {
            const dict = this.dict.find(dict => dict.key === dictKey);
            return dict && dict.value;
        }
    },
    created() {
        initDictByType(this.dictType);
        this.selectedType = typeof this.selected;
    },
    watch: {
        dictType: {
            handler: function (v, o) {
                initDictByType(this.dictType);
                this.dict = getDictType(this.dictType);
            },
            immediate: true
        }
    },
    props: {
        dictType: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: "small"
        },
        selected: {
            type: [String, Array],
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择"
        },
    }
}
</script>

<style scoped>

</style>
