<template>
    <div>
        <div>
            <el-upload
                    action="/上传文件的接口"
                    :on-change="onChange"
                    :auto-upload="false"
                    :show-file-list="false"
                    accept=".xls, .xlsx">
                <slot>
                    <el-button type="warning" size="mini" round>批量导入</el-button>
                </slot>
            </el-upload>
        </div>
    </div>
</template>

<script>
    import XLSX from 'xlsx'
    import {getDictType, getKey} from "@/util/dict";
    import Schema from "async-validator";
    import {initDictByTypes} from "@/util/dict";
    import {DICT_INIT} from "@/util/dictType";

    export default {
        name: "ExcelImport",
        data() {
            return {
                //接受文件数据
                fileData: '',
                //文件输出数据
                outputs: [],
            }
        },
        beforeCreate() {
            initDictByTypes(DICT_INIT);
        },
        props: {
            //属性和表头
            keyAndHeader: {
                type: Object,
                required: true,
                default: {index: "序号", name: "姓名"},
            },
            //必填字段校验
            requiredColumns: {
                type: Array,
                default() {
                    return []
                }
            },
            //上传方法
            uploadService: {
                type: Function,
                required: true
            },
            rules: [Object, Array],
        },
        methods: {
            // 文件选择回调
            onChange(file, fileList) {
                this.fileData = file; // 保存当前选择文件
                this.readExcel(); // 调用读取数据的方法
            },
            // 读取数据
            readExcel(e) {
                if (!this.fileData) {
                    //如果没有文件
                    return false;
                } else if (!/.(xls|xlsx)$/.test(this.fileData.name.toLowerCase())) {
                    this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
                    return false;
                }
                const fileReader = new FileReader();
                fileReader.onload = ev => {
                    try {
                        const data = ev.target.result;
                        const workbook = XLSX.read(data, {
                            type: "binary"
                        });
                        if (!workbook.SheetNames[0]) {
                            this.$message.error("上传表格无数据!")
                        }
                        const wsname = workbook.SheetNames[0]; //取第一张表
                        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
                        this.outputs = []; //清空接收数据
                        for (let i = 0; i < ws.length; i++) {
                            let sheetData = this.getKeyValue(ws, i);
                            this.outputs.push(sheetData)
                        }
                        console.log(this.outputs)
                        //提交数据
                        this.uploadService(this.outputs).then(r => {
                            if (r.code === 200) {
                                this.$message({
                                    message: r.msg,
                                    showClose: true,
                                    type: "success"
                                });
                                //提交成功,触发父组件刷新
                                this.$emit("submitted")
                            } else {
                                this.$message.error('提交失败!');
                            }
                        })
                        this.$refs.upload.value = "";
                    } catch (e) {
                        console.log(e)
                        return false;
                    }
                };
                fileReader.readAsBinaryString(this.fileData.raw);
            },
            //组装数据
            getKeyValue(ws, i) {
                let data = {};
                let keys = Object.keys(this.keyAndHeader)
                for (const k in this.keyAndHeader) {
                    let columnName = ''
                    if (this.keyAndHeader.hasOwnProperty(k)) {
                        if (this.keyAndHeader[k] instanceof Object) {
                            //处理字典数据
                            columnName = this.keyAndHeader[k].name
                            //值为空使用默认值,无默认值填充''
                            if (this.keyAndHeader[k].type && ws[i][columnName]) {
                                data[k] = getKey(getDictType(this.keyAndHeader[k].type), ws[i][columnName]);
                            } else {
                                data[k] = this.keyAndHeader[k].default || ''
                            }
                        } else {
                            columnName= this.keyAndHeader[k]
                            data[k] = ws[i][this.keyAndHeader[k]] || this.keyAndHeader[k].default || '';
                        }
                        //校验数据
                        this.validate(k, data[k], i,columnName)
                    }
                }
                return data;
            },
            //校验
            validate(column, filedValue, i,columnName) {
                let rules = this.rules[column];
                if ((!rules || rules.length === 0)) {
                    return true;
                }
                const descriptor = {};
                if (rules && rules.length > 0) {
                    rules.forEach(rule => {
                        delete rule.trigger;
                    });
                }
                descriptor[column] = rules;
                const validator = new Schema(descriptor);
                const model = {};
                model[column] = filedValue;
                validator.validate(model, {firstFields: true}, (errors, invalidFields) => {
                    if (errors) {
                        this.$message.warning(`第${i+1}行'${columnName }'格式错误:${errors[0].message}`);
                        throw false
                    }
                });
            },

        }
    }
</script>

<style scoped>

</style>
