import router from '@/router'
import {Message} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {getAdminSessionid, getToken} from '@/util/auth'
import store from '@/store'
import {checkSessionid} from "@/service/manage/multi-tenant";

NProgress.configure({showSpinner: false}); // NProgress Configuration

/**
 * 白名单
 * @type {string[]}
 */
const whiteList = ['LoginJL', '404', '401', 'MultiTenantAdminLogin', 'Subscribe'];

export function initializeRouterGuards(router) {
    router.beforeEach(async (to, from, next) => {
        NProgress.start();
        if (to.meta.title) { // 如果设置标题，拦截后设置标题
            document.title = to.meta.title
        }

        //校验分发管理平台登录状态
        if(to.name === 'MultiTenantManageList'){
            let sessionId = to.params.sessionId;
            if(sessionId && getAdminSessionid()){
                checkSessionid(sessionId).then(res => {
                    next()
                }).catch(() => {
                    next(`/admin`);
                });
            }else{
                next(`/admin`);
            }
            return;
        }

        if (getToken()) {
            const permission = store.state.permission;
            if (!permission.initialized) {
                await store.dispatch('initializeRoutes');
                // 动态添加可访问路由表
                const {routesWithoutMenuOnly} = permission;
                routesWithoutMenuOnly.forEach(route => router.addRoute(route));

                next({...to, replace: true}); // hack方法 确保addRoutes已完成
                return;
            }

            //已登录/路由隐藏 则跳转主页
            if (to.path === '/login') {
                next({path: '/'});
                return NProgress.done();
            }
            next();
        } else {
            // 没有token
            if (whiteList.includes(to.name)) {
                // 在免登录白名单，直接进入
                next()
            } else {
                Message.error('请先登录');
                next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
                NProgress.done();
            }
        }
    });

    router.afterEach(() => {
        NProgress.done();
    });

}

initializeRouterGuards(router);
