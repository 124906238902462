<template>
    <el-divider :content-position="position">
        <slot>{{title}}</slot>
    </el-divider>
</template>
<script>
    export default {
        name: "XDivider",
        props: {
            position: {
                type: String,
                default: "left"
            },
            title: {
                type: String,
                default: "分割线"
            }
        }
    }
</script>

<style scoped>

</style>