<template>
    <el-popover @show="refreshTree">
        <div style="width: 300px;">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText" style="width:180px"></el-input>
            <el-button type="success" size="small" icon="el-icon-check" @click="handleConfirm" style="margin-left: 10px"></el-button>
            <el-button type="primary" size="small" icon="el-icon-brush" @click="handleClear"></el-button>
        </div>
        <el-tree ref="tree" :data="treeData" :props="defaultProps"
                 node-key="id"
                 :default-expanded-keys="expanded"
                 :filter-node-method="filterNode"
                 :check-strictly="true"
                 show-checkbox
                 @check-change="handleCheckChange">
            <template slot-scope="{ node, data }">
                    <span :class="data.type === '1' ? 'el-icon-office-building' : 'el-icon-s-flag'">
                        {{data.name}}({{data.subNodes ? data.subNodes.length : 0}})
                    </span>
            </template>
        </el-tree>
        <el-input slot="reference" :type="selection ? 'textarea' : 'text'" v-model="model" size="small" ref="toggle"/>
    </el-popover>
</template>

<script>
	import {mainTree} from '@/service/system/organ';

	export default {
		name: "XOrganSelector",
		data() {
			this.defaultProps = {
				children: 'subNodes',
				label: 'name'
			}
			return {
				filterText: '',
				expanded: [],
				treeData: []
			};
		},
		computed: {
			model: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val)
				}
			}
		},
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			}
		},
		methods: {
			filterNode(value, data) {
				if (!value) return true;
				return data.name.indexOf(value) !== -1;
			},
			refreshTree() {
				mainTree().then((response) => {
					this.setDisabled(response.data);
					this.treeData = response.data;
					let selsArr = this.sels.split(",");
					this.expanded = selsArr;
					this.$refs.tree.setCheckedKeys(selsArr);
				})
			},
			setDisabled(nodes) {
				let node;
				for (let i = 0; i < nodes.length; i++) {
					node = nodes[i];
					if ((this.usable === 'dept' && node.type === '1') || (this.usable === 'organ' && node.type === '2')) {
						node.disabled = true;
					}
					if (node.subNodes && node.subNodes.length > 0) {
						this.setDisabled(node.subNodes);
					}
				}
			},
			handleCheckChange(data, checked, node) {
				//处理单选选中的情况
				if (!this.selection && checked) {
					this.$refs.tree.setCheckedKeys([data.id]);
				}
				let checks = this.$refs.tree.getCheckedNodes();
				if (!checks || checks.length < 0) {
					checks = [];
				}
				this.model = checks.map((obj) => {
					return obj[this.code]
				}).join(",");
				let other = checks.map((obj) => {
					return obj[this.otherKey]
				}).join(",");
				this.$emit("selected", this.$refs.tree.getCheckedNodes());
				this.$emit("update:other", other);
			},
			handleConfirm() {
				this.$emit("selected", this.$refs.tree.getCheckedNodes());
				this.$refs.toggle.$el.click();
			},
			handleClear() {
				this.$refs.tree.setCheckedKeys([]);
				this.$emit("selected", this.$refs.tree.getCheckedNodes());
			}
		},
		props: {
			value: {
				type: String,
			},
			code: {
				type: String,
				default: "name"
			},
			other: {
				type: String,
			},
			otherKey: {
				type: String,
				default: "organCode"
			},
			selection: {
				type: Boolean,
				default: false
			},
			usable: {
				type: String,
				default: "all"
			},
			sels: {
				type: String,
				default: "",
			}
		}
	};
</script>

<style scoped>

</style>
