<script>
    import {noop, getPropByPath} from 'element-ui/src/utils/util';
    import AsyncValidator from 'async-validator';
    import {FormItem} from 'element-ui';

    export default {
        name: "XRowCellValidationItem",
        componentName: 'ElFormItem',    //Compatible with ElFormItem
        extends: FormItem,
        render(h) {
            return <div class={[{
                'el-form-item--feedback': this.elForm && this.elForm.statusIcon,
                'is-error': this.validateState === 'error',
                'is-validating': this.validateState === 'validating',
                'is-success': this.validateState === 'success',
                'is-required': this.isRequired || this.required,
                'is-no-asterisk': this.elForm && this.elForm.hideRequiredAsterisk
            }, 'el-form-item']}>
                {this.$slots.default}
                <div class="validation-item__error">{this.validateMessage}</div>
            </div>;
        },
        props: {
            model: Object,
            rowIndex: Number,
        },
        computed: {
            fieldValue() {
                const rowModel = this.model;
                if (!rowModel || !this.prop) {
                    return;
                }

                let path = this.prop;
                if (path.indexOf(':') !== -1) {
                    path = path.replace(/:/, '.');
                }

                return getPropByPath(rowModel, path, true).v;
            }
        },
        methods: {
            //方法拷贝自 ElFormItem的validate，仅在验证时给validator增加rowModel和rowIndex参数
            validate(trigger, callback = noop) {
                this.validateDisabled = false;
                const rules = this.getFilteredRule(trigger);
                if ((!rules || rules.length === 0) && this.required === undefined) {
                    callback();
                    return true;
                }

                this.validateState = 'validating';

                const descriptor = {};
                if (rules && rules.length > 0) {
                    rules.forEach(rule => {
                        delete rule.trigger;
                    });
                }
                descriptor[this.prop] = rules;

                const validator = new AsyncValidator(descriptor);
                //给validator增加rowModel和rowIndex参数
                const model = {[this.prop]: this.fieldValue, rowModel: this.model, rowIndex: this.rowIndex};

                validator.validate(model, { firstFields: true }, (errors, invalidFields) => {
                    this.validateState = !errors ? 'success' : 'error';
                    this.validateMessage = errors ? errors[0].message : '';

                    callback(this.validateMessage, invalidFields);
                    this.elForm && this.elForm.$emit('validate', this.prop, !errors, this.validateMessage || null);
                });
            },
        }
    };
</script>

<style scoped>
    .validation-item__error {
        color: #F56C6C;
        font-size: 12px;
        line-height: 1;
        top: 23px;
        left: 10px;
        z-index: 10;
        position: absolute;
    }

    .el-form-item {
        margin-bottom: 0;
        width:100%
    }
</style>


