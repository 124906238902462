import {login, logout} from '@/service/system/user'
import {
    getUser,
    removeToken,
    removeUser,
    setToken,
    setUser,
    setTenantKey,
    removeTenantKey
} from '@/util/auth';
import store from '@/store'
import {doNothing} from '@/util/objects';

const state = {
    user: getUser(),
    userInfo: {}
}

const mutations = {
    SET_USER: (state, user) => {
        state.user = user
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {account, password, tenantKey} = userInfo;
        return new Promise((resolve, reject) => {
            login({account: account.trim(), password: password, tenantKey}).then(response => {
                const {data} = response;
                commit('SET_USER', data);
                setToken(data.token);
                setUser(data);
                setTenantKey(tenantKey);
                resolve()
            }).catch(reject)
        })
    },
    // user logout
    logout({commit, dispatch}) {
        return new Promise(async resolve => {
            await logout().catch(doNothing);

            commit('SET_USER', {});
            removeToken();
            removeUser();
            removeTenantKey();
            store.dispatch('viewTag/resetVisitedView').then(resolve);
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
