import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const CGGL_ROUTES = [
    {
        path: '/cggl',
        name: 'Cggl',
        component: Layout,
        meta: {title: '采购管理', isMenu: true, searchable: true},
        redirect: '/cggl/cai-gou-dan-list',
        children: [
            // cgd列表路由
            {
                path: 'cai-gou-dan-list',
                component: () => import('@/view/cggl/cgd/CaiGouDanList'),
                name: 'CaiGouDanList',
                meta: {title: '采购单', isTag: true, isMenu: true, searchable: true}
            },
            // cgd独立查询路由
            {
                path: 'cai-gou-dan-query',
                component: () => import('@/view/cggl/cgd/CaiGouDanQuery'),
                name: 'CaiGouDanQuery',
                meta: {title: '采购单查询', isTag: true, isMenu: true, searchable: true}
            },

            // ckxx列表路由
            {
                path: 'chu-ku-xx-list',
                component: () => import('@/view/cggl/spckd/ChuKuXXList'),
                name: 'ChuKuXXList',
                meta: {title: '出库信息', isTag: true, isMenu: true, searchable: true}
            },
            // ckxx独立查询路由
            {
                path: 'chu-ku-xx-query',
                component: () => import('@/view/cggl/spckd/ChuKuXXQuery'),
                name: 'ChuKuXXQuery',
                meta: {title: '出库信息查询', isTag: true, isMenu: true, searchable: true}
            },

            // ck列表路由
            {
                path: 'cang-ku-list',
                component: () => import('@/view/cggl/ckgl/CangKuList'),
                name: 'CangKuList',
                meta: {title: '仓库', isTag: true, isMenu: true, searchable: true}
            },
            // ck独立查询路由
            {
                path: 'cang-ku-query',
                component: () => import('@/view/cggl/ckgl/CangKuQuery'),
                name: 'CangKuQuery',
                meta: {title: '仓库查询', isTag: true, isMenu: true, searchable: true}
            },

            // ghs列表路由
            {
                path: 'gong-huo-shang-list',
                component: () => import('@/view/cggl/ghs/GongHuoShangList'),
                name: 'GongHuoShangList',
                meta: {title: '供货商', isTag: true, isMenu: true, searchable: true}
            },
            // ghs独立查询路由
            {
                path: 'gong-huo-shang-query',
                component: () => import('@/view/cggl/ghs/GongHuoShangQuery'),
                name: 'GongHuoShangQuery',
                meta: {title: '供货商查询', isTag: true, isMenu: true, searchable: true}
            },

            // thd列表路由
            {
                path: 'tui-huo-dan-list',
                component: () => import('@/view/cggl/cgthgl/TuiHuoDanList'),
                name: 'TuiHuoDanList',
                meta: {title: '退货单', isTag: true, isMenu: true, searchable: true}
            },
            // thd独立查询路由
            {
                path: 'tui-huo-dan-query',
                component: () => import('@/view/cggl/cgthgl/TuiHuoDanQuery'),
                name: 'TuiHuoDanQuery',
                meta: {title: '退货单查询', isTag: true, isMenu: true, searchable: true}
            },

            //列表路由
            {
                path: 'cai-gou-dan-xiang-qing-list',
                component: () => import('@/view/cggl/cgspmx/CaiGouDanXiangQingList'),
                name: 'CaiGouDanXiangQingList',
                meta: {title: '采购单详情', isTag: true, isMenu: true, searchable: true}
            },
            //独立查询路由
            {
                path: 'cai-gou-dan-xiang-qing-query',
                component: () => import('@/view/cggl/cgspmx/CaiGouDanXiangQingQuery'),
                name: 'CaiGouDanXiangQingQuery',
                meta: {title: '采购单详情查询', isTag: true, isMenu: true, searchable: true}
            }
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: CGGL_ROUTES
}
