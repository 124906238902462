import ValidationItem from './XRowCellValidationItem';

export default {
    name: "XRowCell",
    render: h => h(),
    inject: ['xRow'],
    components: {ValidationItem},   //using in XRowCell.vue
    props: {
        width: {
            default: 150
        },
        title: {
            type: String,
            required: true
        },
        align: {
            type: String,
            default: 'left'
        },
        read: String
    },
    mounted() {
        let table = this.xRow;
        table.setTitle({name: this.title, width: this.width});
        let that = this;
        let column = {
            renderCell: (h, row, index) => {
                return (<td align={this.align} width={this.width + 'px'}
                            on-mouseenter={($event) => table.$emit('cell-mouse-enter', $event, row)}
                            on-mouseleave={($event) => table.$emit('cell-mouse-leave', $event, row)}>
                    {that.read ? that.createDetail(row, index) : that.createEditable(row, index)}
                </td>);
            }
        };
        table.addColumn(column);
    },
    methods: {
        createDetail(row, index) {
            let clazz = "row-tip el-tooltip cell " + this.align;
            return (<div class={clazz} style={{'width':`${this.width - 7}px`}}>{ typeof row[this.read] !== 'undefined'? row[this.read]: this.$scopedSlots.default({row: row, $index: index}) }</div>);
        },
        createEditable(row, index) {
            const validationItemData = {
                props: {...this.$attrs, model: row, rowIndex: index},
            };
            return (<validation-item {...validationItemData}>
                {this.$scopedSlots.default({row: row, $index: index})}
            </validation-item>);
        }
    }
};
