<template>
    <div class="x-detail">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "XDetail",
        componentName: 'XDetail',
        props: {
            labelWidth: {
                type: String,
                default: '140px'
            },
            entity: {
                type: Object,
                required: true
            }
        },
        provide() {
            return {'xDetail': this}
        }
    }
</script>

<style scoped>

</style>
