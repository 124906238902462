import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const SPGL_ROUTES = [
    {
        path: '/spgl',
        name: 'Spgl',
        component: Layout,
        meta: {title: '商品管理', isMenu: true, searchable: true},
        redirect: '/spgl/shang-pin-xx-list',
        children: [
            {
                path: 'shang-pin-xx-list',
                component: () => import('@/view/spgl/spxx/ShangPinXXList'),
                name: 'ShangPinXXList',
                meta: {title: '商品信息', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-xx-query',
                component: () => import('@/view/spgl/spxx/ShangPinXXQuery'),
                name: 'ShangPinXXQuery',
                meta: {title: '商品信息查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-fen-lei-list',
                component: () => import('@/view/spgl/spfl/ShangPinFenLeiList'),
                name: 'ShangPinFenLeiList',
                meta: {title: '商品分类', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-fen-lei-query',
                component: () => import('@/view/spgl/spfl/ShangPinFenLeiQuery'),
                name: 'ShangPinFenLeiQuery',
                meta: {title: '商品分类查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-gui-ge-list',
                component: () => import('@/view/spgl/spgg/ShangPinGuiGeList'),
                name: 'ShangPinGuiGeList',
                meta: {title: '商品规格', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-gui-ge-query',
                component: () => import('@/view/spgl/spgg/ShangPinGuiGeQuery'),
                name: 'ShangPinGuiGeQuery',
                meta: {title: '商品规格查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-pin-pai-list',
                component: () => import('@/view/spgl/sppp/ShangPinPinPaiList'),
                name: 'ShangPinPinPaiList',
                meta: {title: '商品品牌', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-pin-pai-query',
                component: () => import('@/view/spgl/sppp/ShangPinPinPaiQuery'),
                name: 'ShangPinPinPaiQuery',
                meta: {title: '商品品牌查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-dw-list',
                component: () => import('@/view/spgl/spdw/ShangPinDWList'),
                name: 'ShangPinDWList',
                meta: {title: '商品单位', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-dw-query',
                component: () => import('@/view/spgl/spdw/ShangPinDWQuery'),
                name: 'ShangPinDWQuery',
                meta: {title: '商品单位查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'recycle-bin-list',
                component: () => import('@/view/spgl/spxx/ShangPinXXRecycleBinList'),
                name: 'ShangPinXXRecycleBinList',
                meta: {title: '商品回收站', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'zeng-pin-ku-list',
                component: () => import('@/view/spgl/zpk/ZengPinKuList'),
                name: 'ZengPinKuList',
                meta: {title: '赠品库', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-gai-kuang-list',
                component: () => import('@/view/spgl/spxx/ShangPinGaiKuangList'),
                name: 'ShangPinGaiKuangList',
                meta: {title: '商品概况', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-lin-qi-list',
                component: () => import('@/view/spgl/spxx/ShangPinLinQiList'),
                name: 'ShangPinLinQiList',
                meta: {title: '商品临期', isTag: true, isMenu: true, searchable: true}
            },
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: SPGL_ROUTES
}
