<template>
    <el-button type="primary" icon="el-icon-printer" :size="buttonSzie" round @click="printForm(formId)">
        <slot>打印</slot>
    </el-button>
</template>
<script>
import Print from './print.js'

export default {
    name: "PrintableForm",
    props: {
        //打印区ID
        formId: {
            type: String,
            required: true
        },
        //按钮大小
        buttonSzie: {
            type: String,
            default: 'small'
        },
        //页面排除内容的ID数组
        ignore: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        printForm(formId) {
            Print(`#${formId}`, {
                //打印结束回调
                afterprint: () => {
                    this.$emit("afterPrint")
                },
                cancel: () => { },
            });
        },
    }
}
</script>

<style scoped>
</style>
