import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const KCGL_ROUTES = [
    {
        path: '/pdgl',
        name: 'pdgl',
        component: Layout,
        meta: {title: '盘点管理', isMenu: true, searchable: true},
        redirect: '/pdgl/pan-dian-xx-list',
        children: [
            //列表路由
            {
                path: 'pan-dian-xx-list',
                component: () => import('@/view/kcgl/pdgl/PanDianXXList'),
                name: 'PanDianXXList',
                meta: {title: '盘点信息', isTag: true, isMenu: true, searchable: true}
            },

            //审核列表路由
            {
                path: 'pan-dian-xx-audit-list',
                component: () => import('@/view/kcgl/pdgl/PanDianXXAuditList'),
                name: 'PanDianXXAuditList',
                meta: {title: '盘点信息审核', isTag: true, isMenu: true, searchable: true}
            },

            //独立查询路由
            {
                path: 'pan-dian-xx-query',
                component: () => import('@/view/kcgl/pdgl/PanDianXXQuery'),
                name: 'PanDianXXQuery',
                meta: {title: '盘点信息查询', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
    {
        path: '/bsgl',
        name: 'bsgl',
        component: Layout,
        meta: {title: '报损管理', isMenu: true, searchable: true},
        redirect: '/bsgl/bao-sun-xx-list',
        children: [
            //列表路由
            {
                path: 'bao-sun-xx-list',
                component: () => import('@/view/kcgl/bsgl/BaoSunXXList'),
                name: 'BaoSunXXList',
                meta: {title: '报损信息', isTag: true, isMenu: true, searchable: true}
            },

            //审核列表路由
            {
                path: 'bao-sun-xx-audit-list',
                component: () => import('@/view/kcgl/bsgl/BaoSunXXAuditList'),
                name: 'BaoSunXXAuditList',
                meta: {title: '报损信息审核', isTag: true, isMenu: true, searchable: true}
            },

            //独立查询路由
            {
                path: 'bao-sun-xx-query',
                component: () => import('@/view/kcgl/bsgl/BaoSunXXQuery'),
                name: 'BaoSunXXQuery',
                meta: {title: '报损信息查询', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
    {
        path: '/bygl',
        name: 'bygl',
        component: Layout,
        meta: {title: '报溢管理', isMenu: true, searchable: true},
        redirect: '/bygl/bao-yi-xx-list',
        children: [
            //列表路由
            {
                path: 'bao-yi-xx-list',
                component: () => import('@/view/kcgl/bygl/BaoYiXXList'),
                name: 'BaoYiXXList',
                meta: {title: '报溢信息', isTag: true, isMenu: true, searchable: true}
            },

            //审核列表路由
            {
                path: 'bao-yi-xx-audit-list',
                component: () => import('@/view/kcgl/bygl/BaoYiXXAuditList'),
                name: 'BaoYiXXAuditList',
                meta: {title: '报溢信息审核', isTag: true, isMenu: true, searchable: true}
            },

            //独立查询路由
            {
                path: 'bao-yi-xx-query',
                component: () => import('@/view/kcgl/bygl/BaoYiXXQuery'),
                name: 'BaoYiXXQuery',
                meta: {title: '报溢信息查询', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
    {
        path: '/gjck',
        name: 'gjck',
        component: Layout,
        meta: {title: '供给仓库', isMenu: true, searchable: true},
        redirect: '/gjck/kcgl-cang-ku-list',
        children: [
            //列表路由
            {
                path: 'kcgl-cang-ku-list',
                component: () => import('@/view/kcgl/gjck/CangKuList'),
                name: 'CangKuList',
                meta: {title: '供给仓库', isTag: true, isMenu: true, searchable: true}
            },

            // //独立查询路由
            // {
            //     path: 'kcgl-cang-ku-query',
            //     component: () => import('@/view/kcgl/gjck/CangKuQuery'),
            //     name: 'CangKuQuery',
            //     meta: {title: '供给仓库查询', isTag: true, isMenu: true, searchable: true}
            // }
        ]
    },
    {
        path: '/rkjl',
        name: '/rkjl',
        component: Layout,
        meta: {title: '入库记录', isMenu: true, searchable: true},
        redirect: '/rkjl/ru-ku-ji-lu-list',
        children: [
            //列表路由
            {
                path: 'ru-ku-ji-lu-list',
                component: () => import('@/view/kcgl/rkjl/RuKuJiLuList'),
                name: 'RuKuJiLuList',
                meta: {title: '入库记录', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/ckjl',
        name: '/ckjl',
        component: Layout,
        meta: {title: '出库记录', isMenu: true, searchable: true},
        redirect: '/ckjl/chu-ku-ji-lu-list',
        children: [
            //列表路由
            {
                path: 'chu-ku-ji-lu-list',
                component: () => import('@/view/kcgl/ckjl/ChuKuJiLuList'),
                name: 'ChuKuJiLuList',
                meta: {title: '出库记录', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/kczk',
        name: '/kczk',
        component: Layout,
        meta: {title: '库存状况', isMenu: true, searchable: true},
        redirect: '/kczk/ku-cun-zhuang-kuang-list',
        children: [
            //列表路由
            {
                path: 'ku-cun-zhuang-kuang-list',
                component: () => import('@/view/kcgl/kczk/KuCunZhuangKuangList'),
                name: 'KuCunZhuangKuangList',
                meta: {title: '库存状况', isTag: true, isMenu: true, searchable: true}
            },

            // //独立查询路由
            // {
            //     path: 'ku-cun-zhuang-kuang-query',
            //     component: () => import('@/view/kcgl/kczk/KuCunZhuangKuangQuery'),
            //     name: 'KuCunZhuangKuangQuery',
            //     meta: {title: '库存状况查询', isTag: true, isMenu: true, searchable: true}
            // }
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: KCGL_ROUTES
}
