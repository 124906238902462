<template>
    <div class="layout">
        <sidebar></sidebar>
        <div class="right-container">
            <Header></Header>
            <Navbar></Navbar>
            <Main-Container></Main-Container>
        </div>
    </div>
</template>

<script>
    import '@/assets/style/layout.scss';

    export default {
        name: 'Layout',
        components: {
            Sidebar: () => import('./Sidebar'),
            Header: () => import('./Header'),
            MainContainer: () => import('./MainContainer'),
            Navbar: () => import('./Navbar')
        }
    }
</script>
