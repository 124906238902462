<template>
    <div class="x-row">
        <div class="x-row-btn" :style="{display: showBtn}">
            <el-button v-if="!noAddBtn" size="mini" type="primary" icon="el-icon-plus" round @click="addRow">添加
            </el-button>
            <el-button v-if="!noCleanBtn" size="mini" type="danger" icon="el-icon-brush" round @click="clearRow">清空
            </el-button>
        </div>
        <div style="display:none;">
            <slot></slot>
        </div>
        <div class="x-row-area">
            <table class="x-row-table"
                   :style="{width:`${width + 62}px`, marginTop: `${(!noAddBtn || !noCleanBtn) ? '10px' : 0}`, height: `${height}px`}">
                <thead>
                <tr>
                    <th width="60">序号<i v-if="!noSort" class="el-icon-sort" @click="resetSortNum"></i></th>
                    <th v-for="(t, index) in title" :key="t.name" :width="`${t.width}px`">
                        <slot :name="'title' + index">{{ t.name }}</slot>
                    </th>
                </tr>
                </thead>
                <x-row-body :rows="rows"
                            :columns="columns"
                            :noCleanBtn="noCleanBtn"
                            :sortReadonly="sortReadonly"
                            @sortChanged="resort">
                </x-row-body>
                <el-tooltip :effect="tooltipEffect" placement="top" ref="tooltip" :content="tooltipContent"/>
            </table>
        </div>
    </div>
</template>

<script>
import {deepCopy, isNotEmpty} from "@/util/objects"
import {getCell} from "element-ui/packages/table/src/util";
import {getStyle, hasClass} from "element-ui/src/utils/dom";
import {debounce, isNumber} from 'min-dash';

export default {
    name: "XRow",
    data() {
        return {
            title: [],
            columns: [],
            width: 0,
            tooltipContent: '',
            modelKeys: Object.keys(this.rowModel)
        }
    },
    provide() {
        return {xRow: this}
    },
    created() {
        if (!this.noCleanBtn) {
            this.title.push({name: '操作', width: 45});
            this.width = 45;
        }

        this.activateTooltip = debounce(function (tooltip) {
            tooltip.handleShowPopper();
        }, 50);

        this.$on('cell-mouse-enter', this.handleCellMouseEnter);
        this.$on('cell-mouse-leave', this.handleCellMouseLeave);
    },
    props: {
        height: {
            type: Number,
            default: 300
        },
        rows: {
            type: Array,
            required: true
        },
        rowModel: {
            type: Object,
            required: true
        },
        showBtn: {
            type: String,
            default: 'block'
        },
        noAddBtn: {
            type: Boolean,
            default: false
        },
        noCleanBtn: {
            type: Boolean,
            default: false
        },
        watchProps: {
            type: Array,
            default: () => []
        },
        tooltipEffect: {
            type: String,
            default: 'dark'
        },
        sortReadonly: {
            type: Boolean,
            default: false
        },
        noSort: {
            type: Boolean,
            default: false
        }
    },
    components: {
        XRowBody: () => import('@/components/x/row/XRowBody')
    },
    beforeMount() {
        this.rowId = 0;
        this.rows.forEach((obj) => obj.key = this.rowId++);
    },
    watch: {
        rows(newVal) {
            if (isNotEmpty(newVal)) {
                newVal.forEach((obj, index) => {
                    // 默认值赋予
                    let objKeys = Object.keys(obj);
                    this.modelKeys.forEach(key => {
                        if (objKeys.indexOf(key) < 0) {
                            this.$set(obj,key,this.rowModel[key])
                        }
                    })
                    if (!isNumber(obj.key)) {
                        obj.key = this.rowId++;
                    }
                    if (!isNumber(obj.sort)) {
                        obj.sort = index + 1;
                    }
                });
            }
        }
    },
    methods: {
        addRow() {
            const sort = this.rows.length === 0 ? 1 : this.rows.length + 1;
            let obj = {...deepCopy(this.rowModel), key: this.rowId++, sort};
            this.rows.push(obj);
        },
        clearRow() {
            this.rows.splice(0);
        },
        setTitle(title) {
            this.title.push(title);
            this.width += parseInt(title.width);
        },
        addColumn(column) {
            this.columns.push(column);
        },
        resort() {
            this.rows.sort((o1, o2) => o1.sort - o2.sort)
        },
        resetSortNum() {
            this.rows.forEach((o, index) => o.sort = index + 1);
            this.resort();
        },
        handleCellMouseEnter(event, row) {
            //copy from el-table-body
            // 判断是否text-overflow, 如果是就显示tooltip
            const cell = getCell(event);
            const cellChild = event.target.querySelector('.cell');
            if (!(hasClass(cellChild, 'el-tooltip') && cellChild.childNodes.length)) {
                return;
            }
            // use range width instead of scrollWidth to determine whether the text is overflowing
            // to address a potential FireFox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1074543#c3
            const range = document.createRange();
            range.setStart(cellChild, 0);
            range.setEnd(cellChild, cellChild.childNodes.length);
            const rangeWidth = range.getBoundingClientRect().width;
            const padding = (parseInt(getStyle(cellChild, 'paddingLeft'), 10) || 0) +
                (parseInt(getStyle(cellChild, 'paddingRight'), 10) || 0);
            if ((rangeWidth + padding > cellChild.offsetWidth || cellChild.scrollWidth > cellChild.offsetWidth) && this.$refs.tooltip) {
                const tooltip = this.$refs.tooltip;
                // TODO 会引起整个 Table 的重新渲染，需要优化
                this.tooltipContent = cell.innerText || cell.textContent;
                tooltip.referenceElm = cell;
                tooltip.$refs.popper && (tooltip.$refs.popper.style.display = 'none');
                tooltip.doDestroy();
                tooltip.setExpectedState(true);
                this.activateTooltip(tooltip);
            }
        },
        handleCellMouseLeave(event) {
            //copy from el-table-body
            const tooltip = this.$refs.tooltip;
            if (tooltip) {
                tooltip.setExpectedState(false);
                tooltip.handleClosePopper();
            }
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
