export const Pages = {
    system: {title: "系统管理", href: "/system.html", icon: 'x-icon-xitong', hide: true},
    hygl: {title: "会员管理", href: "/hygl.html", icon: 'x-icon-xitong', hide: true},
    cggl: {title: "采购管理", href: "/cggl.html", icon: 'x-icon-xitong', hide: true},
    spgl: {title: "商品管理", href: "/spgl.html", icon: 'x-icon-xitong', hide: true},
    mdgl: {title: "门店管理", href: "/mdgl.html", icon: 'x-icon-xitong', hide: true},
    kcgl: {title: "库存管理", href: "/kcgl.html", icon: 'x-icon-xitong', hide: true},
    hlgl: {title: "货流管理", href: "/hlgl.html", icon: 'x-icon-xitong', hide: true},
    tjdgl: {title: "调价单管理", href: "/tjdgl.html", icon: 'x-icon-xitong', hide: true},
    zjgl: {title: "资金管理", href: "/zjgl.html", icon: 'x-icon-xitong', hide: true},
    szgl: {title: "设置管理", href: "/szgl.html", icon: 'x-icon-xitong', hide: true},
    jygl: {title: "经营", href: "/jygl.html", icon: 'x-icon-xitong', hide: true},
    sbgl: {title: "设备", href: "/sbgl.html", icon: 'x-icon-xitong', hide: true},
    yxgl: {title: "营销管理", href: "/yxgl.html", icon: 'x-icon-xitong', hide: true},
}

export const PageSelector = [];

Object.keys(Pages).forEach(key => {
    PageSelector.push({key: Pages[key].href, ...Pages[key]});
});

export const currentPageHref = window.location.pathname;

export let currentPageKey;
if (currentPageHref === '/' || currentPageHref === '\\') {
    currentPageKey = 'index';
} else {
    const matchedKey = Object.keys(Pages).find(key => Pages[key].href === currentPageHref);
    if (!matchedKey) {
        throw new Error(`incorrect page href: [${currentPageHref}], please check it`);
    }
    currentPageKey = matchedKey;
}

export const isInSubsystem = currentPageKey !== 'index';
