import request from '@/util/request'

//获取字典类型列表
export function list(data) {
    return request({
        url: '/dict/top/list',
        method: 'post',
        data
    })
}

//根据pcode 获取字典项
export function listByPcode(pcode) {
    return request({
        url: '/dict/list/' + pcode,
        method: 'get',
    })
}

//获取字典类型
export function getUpdate(id) {
    return request({
        url: '/dict/get-update/' + id,
        method: 'get',
    })
}

//添加字典
export function add(data) {
    return request({
        url: '/dict/add',
        method: 'post',
        data
    })
}

//编辑字典
export function update(data) {
    return request({
        url: '/dict/update',
        method: 'post',
        data
    })
}

//删除字典
export function del(id) {
    return request({
        url: '/dict/del/' + id,
        method: 'post',
    })
}

//固化字典
export function effect(id) {
    return request({
        url: '/dict/effect/' + id,
        method: 'post',
    })
}

//字典生效失效
export function use(data) {
    return request({
        url: '/dict/use',
        method: 'post',
        data
    })
}


export function entry(type) {
    return request({
        url: '/dict/entry/' + type,
        method: 'get'
    });
}

export function entrys(data) {
    return request({
        url: '/dict/entry',
        method: 'post',
        data
    });
}

export function sort(id, sort) {
    return request({
        url: `/dict/sort/${id}/${sort}`,
        method: 'post',
    });
}