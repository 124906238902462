//字典类型常量插件
const dictType = {

    //--- 字典类型，选择元类型，代码生成器 --- start ---

    T_DICT_TYPES: {key: 'T_DICT_TYPES', name: '字典类型'},
    G_SELECTOR: {key: 'G_SELECTOR', name: '选择元列表'},
    A_ATTR: {key: 'AUTO_ATTR', name: '代码生成器-实体属性'},
    A_DICT_SHOW: {key: 'AUTO_DICT_SHOW', name: '代码生成器-字典呈现方式'},
    A_FUNCTION: {key: 'AUTO_FUNCTION', name: '代码生成器-功能'},
    A_TYPE: {key: 'AUTO_TYPE', name: '代码生成器-模板类型'},
    A_SEARCH_MACH: {key: 'AUTO_SEARCH_MACH', name: '代码生成器-查询条件匹配方式'},
    A_SELECT: {key: 'AUTO_SELECT', name: '代码生成器-单选复选'},
    A_RELATION: {key: 'AUTO_RELATION', name: '代码生成器-单选复选'},
    A_CREATE_BASE: {key: 'AUTO_CREATE_BASE', name: '代码生成器-创建文件-java'},
    A_CREATE_REQUEST: {key: 'AUTO_CREATE_REQUEST', name: '代码生成器-创建文件-request'},
    A_CREATE_RESPONSE: {key: 'AUTO_CREATE_RESPONSE', name: '代码生成器-创建文件-response'},
    A_CREATE_VUE: {key: 'AUTO_CREATE_VUE', name: '代码生成器-创建文件-vue'},
    A_CREATE_SQL: {key: 'AUTO_CREATE_SQL', name: '代码生成器-创建文件-sql'},

    //--- 字典类型，选择元类型，代码生成器 --- end ---

    //--- 公共和系统管理 --- start ---

    T_AUDIT_STATUS: {key: 'TYPE_AUDIT_STATUS', name: '公共-审核状态'},
    T_REMIND_STATUS: {key: 'TYPE_REMIND_STATUS', name: '公共-提醒状态'},
    T_REMIND: {key: 'TYPE_REMIND', name: '公共-提醒类型'},
    T_OPEN_OR_CLOSE:{key:"TYPE_OPEN_OR_CLOSE",name:"公共-开启/关闭"},
    T_DATA_AUTHORITY: {key: 'TYPE_DATA_AUTHORITY', name: '公共-数据权限'},
    T_EFFECTIVE: {key: 'TYPE_EFFECTIVE', name: '公共-有效/无效'},
    T_USE: {key: 'TYPE_USE', name: '公共-启用/禁用'},
    T_YES_OR_NO: {key: 'TYPE_YES_OR_NO', name: '公共-是否'},
    T_SECURITY_CLASSIFICATION: {key: 'TYPE_SECURITY_CLASSIFICATION', name: '公共-密级'},
    T_QUARTZ_STATUS: {key: 'TYPE_QUARTZ_STATUS', name: '公共-定时任务状态'},
    T_ORGAN: {key: 'TYPE_ORGAN', name: '系统管理-组织类型'},
    T_ORGAN_LEVEL: {key: 'TYPE_ORGAN_LEVEL', name: '系统管理-组织等级'},
    T_USER_STATUS: {key: 'TYPE_USER_STATUS', name: '系统管理-用户状态'},
    T_USER_TYPE: {key: 'TYPE_USER', name: '系统管理-用户类型'},
    T_USER_MD_OR_SH: {key: 'TYPE_USER_MD_OR_SH', name: '系统管理-用户组织类型'},
    T_OPERATE: {key: 'TYPE_OPERATE', name: '系统管理-子菜单操作类型'},
    T_CONDITION: {key: 'TYPE_CONDITION', name: '系统管理-查询条件'},
    PROCESS_MODEL_STATUS: {key: 'TYPE_PROCESS_MODEL_STATUS', name: '系统管理-自定义流程模型状态'},
    PROCESS_INSTANCE_STATUS: {key: 'TYPE_PROCESS_INSTANCE_STATUS', name: '系统管理-流程实例状态'},
    T_PUSH_FREQUENCY: {key: "TYPE_PUSH_FREQUENCY", name: '公共-推送频率'},
    T_OPER_SIGN: {key: "TYPE_OPER_SIGN", name: '公共-接口数据操作标识'},
    T_ORDER: {key: "TYPE_ORDER", name: '基础信息-排序类型'},
    //--- 公共和系统管理 --- end ---

    //--- 门店管理--- start ---
    T_JING_YING_LEI_XING:{key:"TYPE_JING_YING_LEI_XING",name:'门店管理-经营类型'},
    T_GONG_HUO_SHANG_LEI_XING:{key:"TYPE_GONG_HUO_SHANG_LEI_XING",name:"门店管理-供货商类型"},
    T_ZHU_DIAN_FEN_DIAN:{key:"TYPE_ZHU_DIAN_FEN_DIAN",name:"门店管理-主店分店"},

    //--- 资金管理--- start ---
    T_YFK_OPERATION: {key: "TYPE_YFK_OPERATION", name: '资金管理-预付款变动类型'},
    T_COLLECTION: {key: "TYPE_COLLECTION", name: '资金管理-收款方式'},
    T_COLLECTION_STATUS: {key: "TYPE_COLLECTION_STATUS", name: '资金管理-收款状态'},
    T_PAYMENT: {key: "TYPE_PAYMENT", name: '资金管理-支付方式'},
    T_PAYMENT_STATUS: {key: "TYPE_PAYMENT_STATUS", name: '资金管理-支付状态'},

    //--- 库存管理 --- start ---
    T_DAN_JU_Z_T: {key: "TYPE_DAN_JU_Z_T", name: '库存管理-单据状态'},
    T_PAN_DIAN_STATUS: {key: "TYPE_PAN_DIAN_STATUS", name: '库存管理-盘点状态'},
    T_RU_KU_L_X: {key: "TYPE_RU_KU_L_X", name: '库存管理-入库类型'},
    T_CANG_KU_LX: {key: "TYPE_CANG_KU_LX", name: '库存管理-仓库类型'},


    //--- 商品管理 --- start ---
    T_KU_CUN_STATUS: {key: "TYPE_KU_CUN_STATUS", name: '库存管理-仓库类型'},
    T_SHANG_PIN_SHU_XING:{key:"TYPE_SHANG_PIN_SHU_XING",name:'商品管理-商品属性'},
    T_SHANG_PIN_STATUS:{key:"TYPE_SHANG_PIN_STATUS",name:'商品管理-商品状态'},

    //--- 调价单管理 --- start ---
    T_TIAO_JIA_LX: {key: "TYPE_TIAO_JIA_LX", name: '调价单管理-调价类型'},

    //--- 采购管理 --- start ---
    T_CAI_GOU_TYPE: {key: "DICT_TYPE_CAI_GOU_TYPE", name: '采购管理-采购类型'},
    T_JING_YING_FS: {key: "DICT_TYPE_JING_YING_FS", name: '采购管理-经营方式'},

    //--- 货流管理 --- start ---
    T_RU_KU_TYPE: {key: "TYPE_RU_KU_L_X", name: '货流管理-入库类型'},
    T_RU_KU_STATUS: {key: "TYPE_RU_KU_STATUS", name: '货流管理-入库状态'},
    T_DIAO_BO_STATUS: {key: "DICT_TYPE_DIAO_BO_STATUS", name: '货流管理-调拨状态'},
    T_HUO_LIU_STATUS: {key: "TYPE_HUO_LIU_STATUS", name: '货流管理-货流状态'},
    T_HUO_LIU_SHEN_HE_STATUS: {key: "TYPE_HUO_LIU_SHEN_HE_STATUS", name: '货流管理-货流审核状态'},
    T_CHU_KU_LX: {key: "TYPE_CHU_KU_LX", name: '货流管理-出库类型'},
    T_ROLE: {key: "TYPE_ROLE", name: '货流管理-角色类型'},


    //--- 会员管理--- start ---
    T_HY_TAG: {key: "TYPE_HY_TAG", name: '会员管理-标签类型'},
    T_YHQ_ZT: {key: "TYPE_You_Hui_Quan_ZT", name: '会员管理-优惠券状态'},
    T_YHQ_SY_ZT: {key: "TYPE_YHQ_SY_ZT", name: '会员管理-优惠券状态'},
    T_CHONG_ZHI_FS:{key: "TYPE_CHONG_ZHI_FS", name: "会员管理-充值方式"},
    T_HUI_YUAN_LX:{key: "TYPE_HUI_YUAN_LX", name: "会员管理-会员类型"},
    T_KA_ZT:{key: "TYPE_KA_ZT", name: "会员管理-卡状态"},
    T_BianDong_CJ:{key: "YU_E_BIAN_DONG_CJ", name: "会员管理-变动场景"},
    T_BianDong_LX:{key: "YU_E_BIAN_DONG_LX", name: "会员管理-变动类型"},
    T_DING_DAN_ZT:{key: "DING_DAN_ZT", name: "会员管理-订单状态"},
    //--- 会员管理 --- end ---

    //--- 设置管理--- start ---
    T_SZ_DYLX:{key: "TYPE_SZ_DYLX", name: "设置管理-打印类型"},
    T_SZ_MRSKFS:{key: "TYPE_SZ_MRSKFS", name: "设置管理-默认支付方式"},
    T_JBJE_LX:{key: "TYPE_JBJE_LX", name: "设置管理-钱箱修改方式"},
    T_XIAO_PIAO:{key:"TYPE_XIAO_PIAO",name:"设置管理-小票类型"},
    T_PIAO_ZHI_WIDTH:{key:"TYPE_PIAO_ZHI_WIDTH",name:"设置管理-票纸宽度"},
    //--- 设置管理 --- end ---

    //--- 经营--- start ---
    T_PAY:{key: "TYPE_PAY", name: "经营-支付类型"},
    T_ORDERS:{key: "TYPE_ORDERS", name: "经营-订单类型"},
    T_CUSTOMER:{key: "TYPE_CUSTOMER", name: "经营-顾客类型"},
    T_SELECT_CONDITION:{key:"TYPE_SELECT_CONDITION",name:"经营-查询条件"},
    T_PERIOD:{key:"TYPE_PERIOD",name:"经营-销售时间段"},
    //--- 经营 --- end ---

    //--- 设备--- start ---
    T_FU_PING_SET:{key: "TYPE_FU_PING_SET", name: "设备-副屏设置"},
    //--- 设备 --- end ---

};
//选择元字典
export const G_SELECTOR = [
    //{key: 'KhBiaoQianSelector', value: '客商-客户标签'},

];

export const DICT_TYPE = {};
Object.keys(dictType).forEach(key => DICT_TYPE[key] = {
    key: dictType[key].key,
    name: dictType[key].name,
    data: [],
    refresh: false,
    effective: false
});
export const DICT_INIT = [];
Object.keys(dictType).forEach(key => DICT_INIT.push(key));
