import Layout from '@/components/layout/Layout'

export const CONSTANTROUTES = [{
    path: '/audit',
    component: Layout,
    meta: {isMenu: false},
    children: [
        {
            path: '',
            component: () => import('@/view/system/agency/AuditList'),
            name: 'AuditList',
            meta: {title: '审核代办', isTag: true, isMenu: false, searchable: true}
        }
    ]
},
    {
        path: '/remind',
        component: Layout,
        meta: {isMenu: false},
        children: [
            {
                path: '',
                component: () => import('@/view/system/agency/RemindList'),
                name: 'RemindList',
                meta: {title: '提醒', isTag: true, isMenu: false, searchable: true}
            }
        ]

    }];
