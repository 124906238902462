<template>
    <el-select v-model="model" v-bind="$attrs" v-on="$listeners" :style="$vnode.data.style" :class="$vnode.data.class" :size="size" style="width:100%" multiple>
        <el-option v-for="d in dict" :key="uuid + d.key" :label="d.value" :value="d.key"></el-option>
    </el-select>
</template>

<script>
    import {getDictType, initDictByType} from "@/util/dict"
    import {v4} from 'uuid';

    export default {
        name: "XSelectorMany",
        model: {
            prop: "selected",
            event: "selected"
        },
        data() {
            return {
                dict: getDictType(this.dictType),
                uuid: v4()
            }
        },
        computed: {
            model: {
                get() {
                    if (this.selectedType === 'string') {
                        if (!this.selected) return [];
                        return this.selected.split(",");
                    } else {
                        return this.selected;
                    }
                },
                set(arr) {
                    if (this.selectedType === 'string') {
                        this.$emit("selected", arr.join(","))
                    } else {
                        this.$emit("selected", arr)
                    }
                }
            }
        },
        watch: {
            dictType: {
                handler: function (v, o) {
                    initDictByType(this.dictType);
                    this.dict = getDictType(this.dictType);
                },
                immediate: true
            }
        },
        created() {
            initDictByType(this.dictType);
            this.selectedType = typeof this.selected;
        },
        props: {
            dictType: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: "small"
            },
            selected: {
                type: [String, Array],
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
