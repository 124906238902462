<template>
    <el-checkbox-group v-model="model" v-on="$listeners" :style="$vnode.data.style" :class="$vnode.data.class"
                       :size="size">
        <el-checkbox-button v-for="d in dict" :key="uuid + d.key" :label="d.key">{{ d.value }}</el-checkbox-button>
    </el-checkbox-group>
</template>

<script>
import {getDictType, initDictByType} from "@/util/dict"
import {v4} from 'uuid';

export default {
    name: "XCheckGroup",
    model: {
        prop: "selected",
        event: "selected"
    },
    data() {
        return {
            dict: getDictType(this.dictType),
            uuid: v4()
        }
    },
    computed: {
        model: {
            get() {
                if (this.selectedType === 'string') {
                    if (!this.selected) return [];
                    return this.selected.split(this.separator);
                } else {
                    return this.selected;
                }
            },
            set(arr) {
                if (this.selectedType === 'string') {
                    this.$emit("selected", arr.join(this.separator))
                } else {
                    this.$emit("selected", arr)
                }
            }
        }
    },
    created() {
        initDictByType(this.dictType);
        this.selectedType = typeof this.selected;
    },
    props: {
        dictType: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: "small"
        },
        selected: {
            type: [String, Array],
            required: true
        },
        separator: {
            type: String,
            default: ","
        }
    }
}
</script>

<style scoped>

</style>