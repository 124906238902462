import {getCustomer, removeCustomer, setCustomer} from '@/util/auth';

const state = {
    customer: getCustomer(),
};

const mutations = {
    SET_CUSTOMER: (state, customer) => {
        state.customer = customer
    }
};

const actions = {
    setCustomer({commit}, customer) {
        return new Promise((resolve, reject) => {
            commit('SET_CUSTOMER', customer);
            setCustomer(customer);
            resolve();
        })
    },
    removeCustomer({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            commit('SET_CUSTOMER', {});
            removeCustomer();
            resolve();
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
