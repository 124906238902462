import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const ZJGL_ROUTES = [
    {
        path: '/yfkgl',
        name: 'yfkgl',
        component: Layout,
        meta: {title: '预付款管理', isMenu: true, searchable: true},
        redirect: '/yfkgl/yu-fu-kuan-list',
        children: [
            {
                path: 'yu-fu-kuan-list',
                component: () => import('@/view/zjgl/yfkgl/YuFuKuanList'),
                name: 'YuFuKuanList',
                meta: {title: '预付款', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/cgyfgl',
        name: 'cgyfgl',
        component: Layout,
        meta: {title: '采购应付管理', isMenu: true, searchable: true},
        redirect: '/cgyfgl/cai-gou-ying-fu-kuan-list',
        children: [
            {
                path: 'cai-gou-ying-fu-kuan-list',
                component: () => import('@/view/zjgl/cgyfgl/CaiGouYingFuKuanList'),
                name: 'CaiGouYingFuKuanList',
                meta: {title: '采购应付款', isTag: true, isMenu: true, searchable: true}
            },
            // {
            //     path: 'fu-kuan-ji-lu-list',
            //     component: () => import('@/view/zjgl/cgyfgl/FuKuanJiLuList'),
            //     name: 'FuKuanJiLuList',
            //     meta: {title: '付款记录', isTag: true, isMenu: true, searchable: true}
            // },
            {
                path: 'cai-gou-zi-jin-liu-shui-list',
                component: () => import('@/view/zjgl/cgyfgl/CaiGouZiJinLiuShuiList'),
                name: 'CaiGouZiJinLiuShuiList',
                meta: {title: '采购资金流水', isTag: true, isMenu: true, searchable: true}
            }

        ]
    },
    {
        path: '/thysgl',
        name: '/thysgl',
        component: Layout,
        meta: {title: '退货应收管理', isMenu: true, searchable: true},
        redirect: '/thysgl/tui-huo-ying-shou-kuan-list',
        children: [
            {
                path: 'tui-huo-ying-shou-kuan-list',
                component: () => import('@/view/zjgl/thysgl/TuiHuoYingShouKuanList'),
                name: 'TuiHuoYingShouKuanList',
                meta: {title: '退货应收款', isTag: true, isMenu: true, searchable: true}
            },
            // {
            //     path: 'shou-kuan-ji-lu-list',
            //     component: () => import('@/view/zjgl/thysgl/ShouKuanJiLuList'),
            //     name: 'ShouKuanJiLuList',
            //     meta: {title: '收款记录', isTag: true, isMenu: true, searchable: true}
            // },
            {
                path: 'tui-huo-zi-jin-liu-shui-list',
                component: () => import('@/view/zjgl/thysgl/TuiHuoZiJinLiuShuiList'),
                name: 'TuiHuoZiJinLiuShuiList',
                meta: {title: '退货资金流水', isTag: true, isMenu: true, searchable: true}
            }
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: ZJGL_ROUTES
}
