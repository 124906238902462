import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const SZGL_ROUTES = [
    {
        path: '/zfsz',
        name: 'zfsz',
        component: Layout,
        meta: {title: '支付设置', isMenu: true, searchable: true},
        redirect: '/zfsz/zhi-fu-she-zhi-list',
        children: [
            {
                path: 'zhi-fu-she-zhi-list',
                component: () => import('@/view/szgl/zfsz/ZhiFuSheZhiList'),
                name: 'ZhiFuSheZhiList',
                meta: {title: '支付设置', isTag: true, isMenu: true, searchable: true}
            }

        ]
    },
    {
        path: '/cgsz',
        name: 'cgsz',
        component: Layout,
        meta: {title: '常规设置', isMenu: true, searchable: true},
        redirect: '/cgsz/ji-ben-she-zhi-list',
        children: [
            {
                path: 'ji-ben-she-zhi-list',
                component: () => import('@/view/szgl/cgsz/JiBenSheZhiList'),
                name: 'JiBenSheZhiList',
                meta: {title: '基本设置', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'jiao-ban-list',
                component: () => import('@/view/szgl/cgsz/JiaoBanList'),
                name: 'JiaoBanList',
                meta: {title: '交班', isTag: true, isMenu: true, searchable: true}
            }

        ]
    },
    {
        path: '/jssz',
        name: 'jssz',
        component: Layout,
        meta: {title: '角色设置', isMenu: true, searchable: true},
        redirect: '/jssz/shou-yin-yuan-list',
        children: [
            {
                path: 'shou-yin-yuan-list',
                component: () => import('@/view/szgl/jssz/ShouYinYuanList'),
                name: 'ShouYinYuanList',
                meta: {title: '收银员', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
    {
        path: '/xpsz',
        name: 'xpsz',
        component: Layout,
        meta: {title: '小票设置', isMenu: true, searchable: true},
        redirect: '/xpsz/xiao-piao-she-zhi-list',
        children: [
            {
                path: 'xiao-piao-she-zhi-list',
                component: () => import('@/view/szgl/xpsz/XiaoPiaoSheZhiList'),
                name: 'XiaoPiaoSheZhiList',
                meta: {title: '小票设置', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
    {
        path: '/dzsz',
        name: 'dzsz',
        component: Layout,
        meta: {title: '店长设置', isMenu: true, searchable: true},
        redirect: '/dzsz/men-dian-dian-zhang-list',
        children: [
            {
                path: 'men-dian-dian-zhang-list',
                component: () => import('@/view/mdgl/mdxx/MenDianDianZhangList'),
                name: 'MenDianDianZhangList',
                meta: {title: '店长设置', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/yhgl',
        name: 'yhgl',
        component: Layout,
        meta: {title: '用户管理', isMenu: true, searchable: true},
        redirect: '/yhgl/user',
        children: [
            {
                path: 'user',
                component: () => import('@/view/system/user/UserList'),
                name: 'UserList',
                meta: {title: '用户管理', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
    {
        path: '/jsgl',
        name: 'jsgl',
        component: Layout,
        meta: {title: '角色管理', isMenu: true, searchable: true},
        redirect: '/jsgl/role',
        children: [
            {
                path: 'role',
                component: () => import('@/view/system/role/RoleList'),
                name: 'Role',
                meta: {title: '角色管理', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
]

export default {
    constantRoutes,
    dynamicRoutes: SZGL_ROUTES
}
