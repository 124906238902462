import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const JYGL_ROUTES = [
    {
        path: '/jygl',
        name: 'Jygl',
        component: Layout,
        meta: {title: '经营', isMenu: true, searchable: true},
        redirect: '/jygl/shou-kuan-mx-list',
        children: [
            {
                path: 'shou-kuan-mx-list',
                component: () => import('@/view/jygl/skmx/ShouKuanMXList'),
                name: 'ShouKuanMXList',
                meta: {title: '收款明细', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-xiao-shou-mx-list',
                component: () => import('@/view/jygl/skmx/ShangPinXiaoShouMXList'),
                name: 'ShangPinXiaoShouMXList',
                meta: {title: '商品销售明细', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shang-pin-xiao-shou-hz-list',
                component: () => import('@/view/jygl/skmx/ShangPinXiaoShouHZList'),
                name: 'ShangPinXiaoShouHZList',
                meta: {title: '商品销售汇总', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'zhi-xiao-shang-pin-list',
                component: () => import('@/view/jygl/skmx/ZhiXiaoShangPinList'),
                name: 'ZhiXiaoShangPinList',
                meta: {title: '滞销商品', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'chang-xiao-shang-pin-list',
                component: () => import('@/view/jygl/skmx/ChangXiaoShangPinList'),
                name: 'ChangXiaoShangPinList',
                meta: {title: '畅销商品', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'xiao-shou-tong-ji',
                component: () => import('@/view/jygl/skmx/JingYingXiaoShouTongJiList'),
                name: 'JingYingXiaoShouTongJiList',
                meta: {title: '销售统计', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'xiao-shou-bao-biao',
                component: () => import('@/view/jygl/skmx/XiaoShouBaoBiaoList'),
                name: 'XiaoShouBaoBiaoList',
                meta: {title: '销售报表', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
]

export default {
    constantRoutes,
    dynamicRoutes: JYGL_ROUTES
}
