export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    }
    return fmt
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length)
}

/**
 * 当天最早时间
 * @returns {Date}
 */
export function getTodayStartTime() {
    let date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
}

/**
 * 当天最晚时间
 * @returns {Date}
 */
export function getTodayEndTime() {
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
}

/**
 * 当月最早时间
 * @returns {Date}
 */
export function getMonthStartTime() {
    let date = new Date();
    date.setDate(1)
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0)
    return date;
}

/**
 * 当月最晚时间
 * @returns {Date}
 */
export function getMonthEndTime() {
    let date = new Date();
    let currentMonth = date.getMonth();
    let nextMonth = ++currentMonth;
    let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    let oneDay = 1000 * 60 * 60 * 24;
    let newdate = new Date(nextMonthFirstDay - oneDay);
    newdate.setHours(0);
    newdate.setMinutes(0);
    newdate.setSeconds(0);
    newdate.setMilliseconds(0)
    return newdate;
}

/**
 * 当年最早时间
 * @returns {Date}
 */
export function getYearBeginTime(date=new Date()) {
    let newDate = new Date(date)
    newDate.setMonth(0)
    newDate.setDate(1)
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    return newDate;
}

/**
 * 当年最晚时间
 * @returns {Date}
 */
export function getYearEndTime(date=new Date()) {
    let newDate = new Date(date)
    newDate.setMonth(11)
    newDate.setDate(31)
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    return newDate;
}

export function getDays() {
    var date123 = new Date();
    var datemonth = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);
    var month = date123.getMonth()//获取月份
    var day = date123.getDate()//获取天
    var sum = 0;
    for (var i = 0; i < month; i++) {
        sum += datemonth[i];
    }
    sum += day;
    return sum
}

/**
 * 计算两个日期间隔天数
 * @param dataBegin
 * @param dataEnd
 */
export function between4Day(dateBegin, dateEnd) {
    var dateDiff = new Date(dateEnd).getTime() - new Date(dateBegin).getTime(); //时间差的毫秒数
    return Math.round(Math.abs(dateDiff / (24 * 3600 * 1000)));
}

export function getLaterDay(date, days) {
    var dateT = new Date(date).getTime() + days * (24 * 3600 * 1000)
    var dateEnd = new Date(dateT)
    return dateEnd.toLocaleDateString().replace(/\//g,"-")
}

export function getAge(birthYearMonthDay) {
    const birthDate = new Date(birthYearMonthDay);
    const momentDate = new Date();
    momentDate.setHours(0, 0, 0, 0); //因为new Date()出来的时间是当前的时分秒。我们需要把时分秒重置为0。使后面时间比较更精确
    const thisYearBirthDate = new Date(
        momentDate.getFullYear(),
        birthDate.getMonth(),
        birthDate.getDate()
    );
    const aDate = thisYearBirthDate - birthDate;
    const bDate = momentDate - birthDate;
    let tempAge = momentDate.getFullYear() - birthDate.getFullYear();
    let age = null;
    if (bDate < aDate) {
        tempAge = tempAge - 1;
        age = tempAge < 0 ? 0 : tempAge;
    } else {
        age = tempAge;
    }
    return age;
}
