import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '@/store'
import {getToken, getTenantKey, getFixedHeaders} from '@/util/auth';

// create an axios instance
const service = axios.create({
    baseURL: BASE.IS_DOCKER ? BASE.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000000 // request timeout
});

const affixRequestHeader = function (headers) {
    headers.Authorization || (headers.Authorization = `Bearer ${store.getters.token}`);
    headers['X-Tenant-Id'] || (headers['X-Tenant-Id'] = getTenantKey());

    const fixedHeaders = getFixedHeaders();
    if (fixedHeaders) {
        Object.entries(fixedHeaders).map(([key, value]) => headers[key] = value);
    }
};

// request interceptor
service.interceptors.request.use(
    config => {
        const headers = config.headers;
        affixRequestHeader(headers);
        return config
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error)
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;
        if (response.headers['content-type'] === 'application/json' && res.code !== 200) {
            if (!response.config.noErrorMsg) { // 不提示信息 wc
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                });
            }
            return Promise.reject(new Error(res.msg || 'Error'));
        } else {
            return res;
        }
    },
    error => {
        console.log('err' + error); // for debug
        let {response: {status, data} = {}, response} = error;
        if (!response || !status) {
            return Message({
                message: '网络错误，服务暂时不可用！',
                type: 'error',
                duration: 5 * 1000
            })
        }
        if (status === 401 && getToken()) {
            //非法token/token已过期
            MessageBox.confirm('您的登录状态已过期，你可以点击取消继续留在当前页面或点击确认重新登录', '确认登出', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                store.dispatch('user/logout').then(() => {
                    location.reload()
                })
            });
            return Promise.reject(new Error((data && data.msg) || 'Error'));
        } else {
            Message({
                message: error.msg,
                type: 'error',
                duration: 5 * 1000
            });
        }
        return Promise.reject(error);
    }
);

export default service
