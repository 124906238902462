import request from '@/util/request'

export function login({tenantKey, ...rest}) {
    return request({
        url: '/user/login',
        method: 'post',
        headers: {'X-Tenant-Id': tenantKey},
        data: rest
    })
}

export function logout() {
    return request({
        url: '/user/logout',
        method: 'post'
    })
}

export function getUserById(id) {
    return request({
        url: `/user/get-user-by-id/${id}`,
        method: 'get'
    })
}

export function list(data) {
    return request({
        url: '/user/list',
        method: 'post',
        data
    })
}

export function add(data) {
    return request({
        url: '/user/add',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: '/user/update',
        method: 'post',
        data
    })
}

export function get(id) {
    return request({
        url: '/user/edit/' + id,
        method: 'post',
    })
}

export function del(id) {
    return request({
        url: '/user/delete/' + id,
        method: 'post',
    })
}

export function deleteBatch(data) {
    return request({
        url: '/user/delete',
        method: 'post',
        data
    })
}

export function roleOption() {
    return request({
        url: '/role/option',
        method: 'get',
    })
}

export function fetchDetail() {
    return request({
        url: '/user/detail',
        method: 'get',
    })
}

export function changeUserOrganBatch(data) {
    return request({
        url: '/user/change-user-organ-batch',
        method: 'post',
        data
    })
}

export function userSelector() {
    return request({
        url: '/user/user-selector',
        method: 'get'
    });
}

export function changePassword(data) {
    return request({
        url: '/user/change-pwd',
        method: 'post',
        data
    })
}

export function unlock(id) {
    return request({
        url: `/user/unlock/${id}`,
        method: 'get'
    })
}

export function resetPwd(id){
    return request({
        url: '/user/reset-pwd/' + id,
        method: 'post',
    })
}

export function resetAllPwd(){
    return request({
        url: '/user/reset-all-pwd',
        method: 'post',
    })
}