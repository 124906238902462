import {SESSION_STORAGE} from '@/util/storage'
import {deepCopy, pushIfAbsent, remove} from '@/util/objects';
import {currentPageKey} from '@/pages/pages';


const TAGS_KEY = "ADMIN-TAGS";

const DEFAULT_STATE = {
    tags: [],
    cachedViews: []
};

let tagsCache;
const state = ((tagsCache = SESSION_STORAGE.get(TAGS_KEY)) && tagsCache[currentPageKey]) || deepCopy(DEFAULT_STATE);

const mutations = {
    /**
     * 删除标签页
     * @param {*} state
     * @param {*} targetPath
     */
    REMOVE_TABS_VALUE({tags}, targetPath) {
        const tagToRemove = tags.find(item => item.path === targetPath);
        //更新visitedTags
        return tagToRemove && remove(tags, tagToRemove);
    },

    /**
     * 添加标签页，在MenuItem组件触发
     * @param {*} state
     * @param {*} data
     */
    ADD_TABS_VALUE({tags}, {name, path, parent, children, ...rest}) {
        // 判断是否已经存在，优先使用name匹配，以适配同name不同path的路由：如使用带路由参数的组件时
        const existing = tags.find(item => (name && name === item.name) || (item.path === path));
        // 如果不存在添加
        if (!existing) {
            tags.push({...rest, name, path});
        } else {
            //存在且path不同则更新path
            if (existing.path !== path) {
                existing.path = path;
            }
        }
    },

    /**
     * 初始化数据，在注销时调用，不然切换用户后还是旧标签数据
     * @param {*} state
     */
    RESET_TABS_VALUE(state) {
        SESSION_STORAGE.remove(TAGS_KEY);
        const {tags, cachedViews} = deepCopy(DEFAULT_STATE);
        state.tags = tags;
        state.cachedViews = cachedViews;
    },

    REFRESH_CACHE_WITHIN_SESSION(state) {
        const tagsCache = SESSION_STORAGE.get(TAGS_KEY) || {};
        tagsCache[currentPageKey] = state;
        SESSION_STORAGE.set(TAGS_KEY, tagsCache);
    },

    /**
     * 添加缓存视图
     */
    ADD_CACHED_VIEW(state, {name, noCache}) {
        //未禁用缓存时将componentName加入可缓存name列表
        !noCache && pushIfAbsent(state.cachedViews, name);
    },

    /**
     * 移除缓存视图
     */
    REMOVE_CACHED_VIEW(state, {name, noCache}) {
        !noCache && remove(state.cachedViews, name);
    }
};

const actions = {
    addVisitedView({commit, state}, view) {
        return new Promise(resolve => {
            commit('ADD_TABS_VALUE', view);
            commit('ADD_CACHED_VIEW', view);
            commit('REFRESH_CACHE_WITHIN_SESSION');

            resolve({
                tags: [...state.tags],
                cachedViews: [...state.cachedViews]
            })
        })
    },
    removeVisitedView({commit, state}, view) {
        return new Promise(resolve => {
            commit('REMOVE_TABS_VALUE', view.path);
            commit('REMOVE_CACHED_VIEW', view);
            commit('REFRESH_CACHE_WITHIN_SESSION');

            resolve({
                tags: [...state.tags],
                cachedViews: [...state.cachedViews]
            })
        })
    },
    removeCachedView({commit, state}, view) {
        return new Promise(resolve => {
            commit('REMOVE_CACHED_VIEW', view);
            commit('REFRESH_CACHE_WITHIN_SESSION');

            resolve({
                cachedViews: [...state.cachedViews]
            });
        })
    },
    resetVisitedView({commit}) {
        return new Promise(resolve => {
            commit('RESET_TABS_VALUE');
            resolve({
                tags: [...state.tags],
                cachedViews: [...state.cachedViews]
            });
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

