import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const SYSTEM_ROUTES = [
    // {
    //     path: '/hygl',
    //     name: 'Hygl',
    //     component: Layout,
    //     meta: {title: '会员管理', isMenu: true, searchable: true},
    //     redirect: '/hygl/hui-yuan-xx-list',
    //     children: [
    //         // 列表路由
    //         {
    //             path: 'hui-yuan-xx-list',
    //             component: () => import('@/view/hygl/hyxx/HuiYuanXXList'),
    //             name: 'HuiYuanXXList',
    //             meta: {title: '会员信息', isTag: true, isMenu: true, searchable: true}
    //         },
    //
    //         // 独立查询路由
    //         {
    //             path: 'hui-yuan-xx-query',
    //             component: () => import('@/view/hygl/hyxx/HuiYuanXXQuery'),
    //             name: 'HuiYuanXXQuery',
    //             meta: {title: '会员信息查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'hui-yuan-dj-list',
    //             component: () => import('@/view/hygl/hydj/HuiYuanDJList'),
    //             name: 'HuiYuanDJList',
    //             meta: {title: '会员等级', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'chong-zhi-ji-lu-list',
    //             component: () => import('@/view/hygl/czjl/ChongZhiJiLuList'),
    //             name: 'ChongZhiJiLuList',
    //             meta: {title: '充值记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'chong-zhi-ji-lu-query',
    //             component: () => import('@/view/hygl/czjl/ChongZhiJiLuQuery'),
    //             name: 'ChongZhiJiLuQuery',
    //             meta: {title: '充值记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'dong-jie-ji-lu-list',
    //             component: () => import('@/view/hygl/djjl/DongJieJiLuList'),
    //             name: 'DongJieJiLuList',
    //             meta: {title: '冻结记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'dong-jie-ji-lu-query',
    //             component: () => import('@/view/hygl/djjl/DongJieJiLuQuery'),
    //             name: 'DongJieJiLuQuery',
    //             meta: {title: '冻结记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'jie-dong-ji-lu-list',
    //             component: () => import('@/view/hygl/jdjl/JieDongJiLuList'),
    //             name: 'JieDongJiLuList',
    //             meta: {title: '解冻记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'jie-dong-ji-lu-query',
    //             component: () => import('@/view/hygl/jdjl/JieDongJiLuQuery'),
    //             name: 'JieDongJiLuQuery',
    //             meta: {title: '解冻记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'xiao-ka-ji-lu-list',
    //             component: () => import('@/view/hygl/xkjl/XiaoKaJiLuList'),
    //             name: 'XiaoKaJiLuList',
    //             meta: {title: '销卡记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'xiao-ka-ji-lu-query',
    //             component: () => import('@/view/hygl/xkjl/XiaoKaJiLuQuery'),
    //             name: 'XiaoKaJiLuQuery',
    //             meta: {title: '销卡记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'ji-fen-ji-lu-list',
    //             component: () => import('@/view/hygl/jfjl/JiFenJiLuList'),
    //             name: 'JiFenJiLuList',
    //             meta: {title: '积分记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'ji-fen-ji-lu-query',
    //             component: () => import('@/view/hygl/jfjl/JiFenJiLuQuery'),
    //             name: 'JiFenJiLuQuery',
    //             meta: {title: '积分记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'yu-ej-i-lu-list',
    //             component: () => import('@/view/hygl/yejl/YuEJiLuList'),
    //             name: 'YuEJiLuList',
    //             meta: {title: '余额记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'yu-ej-i-lu-query',
    //             component: () => import('@/view/hygl/yejl/YuEJiLuQuery'),
    //             name: 'YuEJiLuQuery',
    //             meta: {title: '余额记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'bian-geng-ji-lu-list',
    //             component: () => import('@/view/hygl/bgjl/BianGengJiLuList'),
    //             name: 'BianGengJiLuList',
    //             meta: {title: '变更记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'bian-geng-ji-lu-query',
    //             component: () => import('@/view/hygl/bgjl/BianGengJiLuQuery'),
    //             name: 'BianGengJiLuQuery',
    //             meta: {title: '变更记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'hui-yuan-ding-dan-ji-lu-list',
    //             component: () => import('@/view/hygl/hyddjl/HuiYuanDingDanJiLuList'),
    //             name: 'HuiYuanDingDanJiLuList',
    //             meta: {title: '会员订单记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'hui-yuan-ding-dan-ji-lu-query',
    //             component: () => import('@/view/hygl/hyddjl/HuiYuanDingDanJiLuQuery'),
    //             name: 'HuiYuanDingDanJiLuQuery',
    //             meta: {title: '会员订单记录查询', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'tui-kuan-ji-lu-list',
    //             component: () => import('@/view/hygl/tkjl/TuiKuanJiLuList'),
    //             name: 'TuiKuanJiLuList',
    //             meta: {title: '退款记录', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'hui-yuan-fen-xi-list',
    //             component: () => import('@/view/hygl/hyfx/HuiYuanFenXiList'),
    //             name: 'HuiYuanFenXiList',
    //             meta: {title: '会员分析', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'hui-yuan-tong-ji',
    //             component: () => import('@/view/hygl/hytj/HuiYuanTongJi'),
    //             name: 'HuiYuanTongJi',
    //             meta: {title: '会员统计', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'ji-fen-di-kou-she-zhi-edit',
    //             component: () => import('@/view/hygl/jfdksz/JiFenDiKouSheZhiEdit'),
    //             name: 'JiFenDiKouSheZhiEdit',
    //             meta: {title: '积分抵扣设置', isTag: true, isMenu: true, searchable: true}
    //         }
    //     ]
    // },
    {
        path: '/hyxx',
        name: 'Hyxx',
        component: Layout,
        meta: {title: '会员信息', isMenu: true, searchable: true},
        redirect: '/hyxx/hui-yuan-xx-list',
        children: [
            {
                path: 'hui-yuan-biao-qian-list',
                component: () => import('@/view/hygl/hybq/HuiYuanBiaoQianList'),
                name: 'HuiYuanBiaoQianList',
                meta: {title: '会员标签', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'cg-hui-yuan-xx-list',
                component: () => import('@/view/hygl/hyxx/HuiYuanXXList'),
                name: 'HuiYuanXXList',
                meta: {title: '常规会员', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'plus-hui-yuan-xx-list',
                component: () => import('@/view/hygl/hyxx/PLUSHuiYuanXXList'),
                name: 'PLUSHuiYuanXXList',
                meta: {title: 'PLUS会员', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/hydj',
        name: 'Hydj',
        component: Layout,
        meta: {title: '会员等级', isMenu: true, searchable: true},
        redirect: '/hydj/hui-yuan-dj-list',
        children: [
            {
                path: 'hui-yuan-dj-list',
                component: () => import('@/view/hygl/hydj/HuiYuanDJList'),
                name: 'HuiYuanDJList',
                meta: {title: '会员等级', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/czgl',
        name: 'Czgl',
        component: Layout,
        meta: {title: '充值管理', isMenu: true, searchable: true},
        redirect: '/czgl/hui-yuan-chong-zhi',
        children: [
            {
                path: 'hui-yuan-chong-zhi',
                component: () => import('@/view/hygl/czgl/HuiYuanChongZhi'),
                name: 'HuiYuanChongZhi',
                meta: {title: '会员充值', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'hui-yuan-yu-e',
                component: () => import('@/view/hygl/czgl/HuiYuanYuE'),
                name: 'HuiYuanYuE',
                meta: {title: '客户余额', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'chong-zhi-ji-lu-list',
                component: () => import('@/view/hygl/czjl/ChongZhiJiLuList'),
                name: 'ChongZhiJiLuList',
                meta: {title: '充值记录', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/xxjl',
        name: 'Xxjl',
        component: Layout,
        meta: {title: '信息记录', isMenu: true, searchable: true},
        redirect: '/xxjl/dong-jie-ji-lu-list',
        children: [
            {
                path: 'dong-jie-ji-lu-list',
                component: () => import('@/view/hygl/djjl/DongJieJiLuList'),
                name: 'DongJieJiLuList',
                meta: {title: '冻结记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'jie-dong-ji-lu-list',
                component: () => import('@/view/hygl/jdjl/JieDongJiLuList'),
                name: 'JieDongJiLuList',
                meta: {title: '解冻记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'xiao-ka-ji-lu-list',
                component: () => import('@/view/hygl/xkjl/XiaoKaJiLuList'),
                name: 'XiaoKaJiLuList',
                meta: {title: '销卡记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'ji-fen-ji-lu-list',
                component: () => import('@/view/hygl/jfjl/JiFenJiLuList'),
                name: 'JiFenJiLuList',
                meta: {title: '积分记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'yu-ej-i-lu-list',
                component: () => import('@/view/hygl/yejl/YuEJiLuList'),
                name: 'YuEJiLuList',
                meta: {title: '余额记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'bian-geng-ji-lu-list',
                component: () => import('@/view/hygl/bgjl/BianGengJiLuList'),
                name: 'BianGengJiLuList',
                meta: {title: '变更记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'hui-yuan-ding-dan-ji-lu-list',
                component: () => import('@/view/hygl/hyddjl/HuiYuanDingDanJiLuList'),
                name: 'HuiYuanDingDanJiLuList',
                meta: {title: '会员订单记录', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'tui-kuan-ji-lu-list',
                component: () => import('@/view/hygl/tkjl/TuiKuanJiLuList'),
                name: 'TuiKuanJiLuList',
                meta: {title: '退款记录', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/hyfx',
        name: 'Hyfx',
        component: Layout,
        meta: {title: '会员分析', isMenu: true, searchable: true},
        redirect: '/hyfx/hui-yuan-fen-xi-list',
        children: [
            {
                path: 'hui-yuan-fen-xi-list',
                component: () => import('@/view/hygl/hyfx/HuiYuanFenXiList'),
                name: 'HuiYuanFenXiList',
                meta: {title: '会员分析', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    {
        path: '/hytj',
        name: 'Hytj',
        component: Layout,
        meta: {title: '会员统计', isMenu: true, searchable: true},
        redirect: '/hytj/hui-yuan-tong-ji',
        children: [
            {
                path: 'hui-yuan-tong-ji',
                component: () => import('@/view/hygl/hytj/HuiYuanTongJi'),
                name: 'HuiYuanTongJi',
                meta: {title: '会员统计', isTag: true, isMenu: true, searchable: true}
            },
        ]
    },
    // {
    //     path: '/yhq',
    //     name: 'Yhq',
    //     component: Layout,
    //     meta: {title: '优惠券', isMenu: true, searchable: true},
    //     redirect: '/yhq/hui-yuan-you-hui-quan-list',
    //     children: [
    //         {
    //             path: 'hui-yuan-you-hui-quan-list',
    //             component: () => import('@/view/hygl/yhq/HuiYuanYouHuiQuanList'),
    //             name: 'HuiYuanYouHuiQuanList',
    //             meta: {title: '会员优惠券', isTag: true, isMenu: true, searchable: true}
    //         },
    //         {
    //             path: 'you-hui-quan-fa-fang-ji-lu-list',
    //             component: () => import('@/view/hygl/yhqffjl/YouHuiQuanFaFangJiLuList'),
    //             name: 'YouHuiQuanFaFangJiLuList',
    //             meta: {title: '优惠券发放记录', isTag: true, isMenu: true, searchable: true}
    //         }
    //     ]
    // },
    {
        path: '/jfsz',
        name: 'Jfsz',
        component: Layout,
        meta: {title: '积分设置', isMenu: true, searchable: true},
        redirect: '/jfsz/ji-fen-di-kou-she-zhi-edit',
        children: [
            {
                path: 'ji-fen-di-kou-she-zhi-edit',
                component: () => import('@/view/hygl/jfdksz/JiFenDiKouSheZhiEdit'),
                name: 'JiFenDiKouSheZhiEdit',
                meta: {title: '积分抵扣设置', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
]

export default {
    constantRoutes,
    dynamicRoutes: SYSTEM_ROUTES
}
