<template>
    <div style="width:100%">
        <el-radio
                :value="value"
                @input="$emit('input', $event)"
                v-bind="$attrs"
                v-on="$listeners"
                :style="$vnode.data.style"
                :class="$vnode.data.class"
                :size="size"
                v-for="d in dict" :key="uuid + d.key" :label="d.key">{{d.value}}
        </el-radio>
    </div>
</template>

<script>
    import {getDictType, initDictByType} from "@/util/dict"
    import {v4} from 'uuid';

    export default {
        name: "XRadio",
        props: {
            dictType: {
                type: String,
                required: true,
            },
            size: {
                type: String,
                default: "small"
            },
            value: {}
        },
        data() {
            return {
                dict: getDictType(this.dictType),
                uuid: v4()
            }
        },
        created() {
            initDictByType(this.dictType);
        },
    }
</script>

<style scoped>

</style>
