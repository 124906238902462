import * as dict from '@/service/system/dict'
import {LOCAL_STORAGE} from '@/util/storage'
import {DICT_INIT, DICT_TYPE, G_SELECTOR} from '@/util/dictType'

const EXPIRES = 5 * 60 * 1000;  //5分钟
const KEY_PREFIX = 'dict_';

export function initDictByTypes(types) {
    if (types && types.length > 0) {
        return Promise.all(types.map((type) => entry(type)));
    }
    return Promise.resolve();
}

export function initDictByType(type) {
    if (type) {
        return entry(type);
    }
    return Promise.resolve();
}


function entryDict() {
    let entry = [];
    Object.keys(DICT_TYPE).forEach(key => entry.push({key: key, value: DICT_TYPE[key].name}));
    DICT_TYPE['T_DICT_TYPES'].data = entry;
}

entryDict();

function entryDictGSelector() {
    DICT_TYPE['G_SELECTOR'].data = G_SELECTOR;
}

entryDictGSelector();

function entry(type) {
    if (DICT_TYPE[type].refresh) {
        return DICT_TYPE[type].refresh;
    }
    let wrappedKey = wrapKey(type);
    let entry = LOCAL_STORAGE.get(wrappedKey);
    if (!entry) {
        DICT_TYPE[type].refresh = dict.entry(DICT_TYPE[type].key);
        DICT_TYPE[type].effective = false;

        return DICT_TYPE[type].refresh.then(response => {
            entry = response.data;
            if (entry) {
                LOCAL_STORAGE.set(wrappedKey, entry, EXPIRES);
                f(entry, type);
            }
        });
    } else {
        f(entry, type);
        return Promise.resolve();
    }
}

function f(entry, type) {
    if (entry && entry.length > 0) {
        let data = DICT_TYPE[type].data;
        if (!DICT_TYPE[type].effective) {
            data.length = 0;
            entry.forEach((obj) => {
                data.push(obj);
            });
            DICT_TYPE[type].effective = true;
        }
    }
}

export function getDictType(type) {
    return DICT_TYPE[type].data;
}

export function getNames(type, codes) {
    let data = DICT_TYPE[type].data;
    //多选
    codes = '' + codes;
    if (codes.indexOf(',') > -1) {
        let code = codes.split(',');
        let names = [];
        code.forEach((c) => {
            names.push(getName(data, c));
        })
        return names.join(',');
    } else {//单选
        return getName(data, codes);
    }
}

export function getName(data, code) {
    let name = '';
    if (data && data.length > 0) {
        data.forEach((obj) => {
            if (obj.key == code) {
                name = obj.value;
            }
        })
    }
    return name;
}

export function getNameCascader(data, code) {
    let name = '';
    if (data && data.length > 0) {
        data.forEach((obj) => {
            if (obj.key === code) {
                name = obj.value.name;
            }
        })
    }
    return name;
}

export function getKey(data, value) {
    let name = '';
    if (data && data.length > 0) {
        data.forEach((obj) => {
            if (obj.value == value) {
                name = obj.key;
            }
        })
    }
    return name;
}

export function clearCache() {
    let keys = _local.keys();
    if (keys.length > 0) {
        keys.filter(element => element.startsWith(KEY_PREFIX)).forEach(element => _local.remove(element));
    }
}

function wrapKey(raw) {
    return KEY_PREFIX + raw;
}
