import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const TJDGL_ROUTES = [
    {
        path: '/tjdgl',
        name: 'tjdgl',
        component: Layout,
        meta: {title: '调价单管理', isMenu: true, searchable: true},
        redirect: '/tjdgl/tiao-jia-xx-list',
        children: [
            {
                path: 'tiao-jia-xx-list',
                component: () => import('@/view/tjdgl/TiaoJiaXXList'),
                name: 'TiaoJiaXXList',
                meta: {title: '调价信息', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },
]

export default {
    constantRoutes,
    dynamicRoutes: TJDGL_ROUTES
}
