import request from '@/util/request'

export function mainTree() {
    return request({
        url: '/organ/main-tree',
        method: 'get'
    });
}

export function add(data) {
    return request({
        url: '/organ/add',
        method: 'post',
        data
    });
}

export function update(data) {
    return request({
        url: '/organ/update',
        method: 'post',
        data
    });
}

export function del(id) {
    return request({
        url: '/organ/del/' + id,
        method: 'get'
    });
}
