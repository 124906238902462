import Cookies from 'js-cookie'
import {LOCAL_STORAGE} from '@/util/storage'

const TokenKey = 'station_admin_token';

const UserKey = 'station_admin-user';

const TenantKey = 'station_tenant_key';

const CustomKey = 'station_e_shop_customer';

const FixedHeaderKey = 'station_fixed_header';

const AdminSessionidKey = 'admin_sessionid_key';

let transientTenantKey;

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function getAdminSessionid() {
    return Cookies.get(AdminSessionidKey)
}

export function setAdminSessionid(sessionid) {
    return Cookies.set(AdminSessionidKey, sessionid)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function setUser(user) {
    return Cookies.set(UserKey, JSON.stringify(user))
}

export function getUser() {
    return JSON.parse(Cookies.get(UserKey) || '{}');
}

export function removeUser() {
    return Cookies.remove(UserKey)
}

export function getTenantKey() {
    if (transientTenantKey) return transientTenantKey;
    return Cookies.get(TenantKey) || LOCAL_STORAGE.get(TenantKey)
}

export function setTenantKey(tenantKey, transient) {
    const tenantKeyStr = `${tenantKey}`;
    if (tenantKey) {
        if (transient) {
            transientTenantKey = tenantKey;
        } else {
            Cookies.set(TenantKey, tenantKeyStr);
            if (!Cookies.get(TenantKey)) {
                LOCAL_STORAGE.set(TenantKey, tenantKeyStr)
            }
        }
    }
}

export function removeTenantKey() {
    if (transientTenantKey) transientTenantKey = null;
    return Cookies.remove(TenantKey) & LOCAL_STORAGE.remove(TenantKey);
}

export function getCustomerToken() {
    const customer = getCustomer();
    return customer && customer.token;
}

export function getCustomer() {
    const customer = Cookies.get(CustomKey);
    if (customer) {
        return JSON.parse(Cookies.get(CustomKey) || '{}');
    }
    return LOCAL_STORAGE.get(CustomKey);
}

export function setCustomer(customer) {
    Cookies.set(CustomKey, JSON.stringify(customer));
    const persistCustomer = Cookies.get(CustomKey);
    if (!persistCustomer) {
        LOCAL_STORAGE.set(CustomKey, customer);
    }
    return customer
}

export function removeCustomer() {
    Cookies.remove(CustomKey);
    LOCAL_STORAGE.remove(CustomKey);
}

export function setFixedHeaders(headers) {
    LOCAL_STORAGE.set(FixedHeaderKey, JSON.stringify((headers)));
}

export function getFixedHeaders() {
    return LOCAL_STORAGE.get(FixedHeaderKey);
}
