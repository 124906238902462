import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const HLGL_ROUTES = [
    {
        path: '/hlgl',
        name: 'Hlgl',
        component: Layout,
        meta: {title: '货流管理', isMenu: true, searchable: true},
        redirect: '/hlgl/ru-ku-xx-list',
        children: [
            //ruxx列表路由
            {
                path: 'ru-ku-xx-list',
                component: () => import('@/view/hlgl/rkd/RuKuXXList'),
                name: 'RuKuXXList',
                meta: {title: '入库信息', isTag: true, isMenu: true, searchable: true}
            },
            //rkxx独立查询路由
            {
                path: 'ru-ku-xx-query',
                component: () => import('@/view/hlgl/rkd/RuKuXXQuery'),
                name: 'RuKuXXQuery',
                meta: {title: '入库信息查询', isTag: true, isMenu: true, searchable: true}
            },

            //ckxx列表路由
            {
                path: 'hlgl-chu-ku-xx-list',
                component: () => import('@/view/cggl/spckd/ChuKuXXList'),
                name: 'ChuKuXXList',
                meta: {title: '出库信息', isTag: true, isMenu: true, searchable: true}
            },
            //ckxx独立查询路由
            {
                path: 'hlgl-chu-ku-xx-query',
                component: () => import('@/view/cggl/spckd/ChuKuXXQuery'),
                name: 'ChuKuXXQuery',
                meta: {title: '出库信息查询', isTag: true, isMenu: true, searchable: true}
            },

            // dbsq列表路由
            {
                path: 'diao-bo-shen-qing-list',
                component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingList'),
                name: 'DiaoBoShenQingList',
                meta: {title: '调拨申请', isTag: true, isMenu: true, searchable: true}
            },
            // dbsq审核列表路由
            {
                path: 'diao-bo-shen-qing-audit-list',
                component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingAuditList'),
                name: 'DiaoBoShenQingAuditList',
                meta: {title: '调拨申请审核', isTag: true, isMenu: true, searchable: true}
            },
            // dbsq独立查询路由
            {
                path: 'diao-bo-shen-qing-query',
                component: () => import('@/view/hlgl/sqdbgl/DiaoBoShenQingQuery'),
                name: 'DiaoBoShenQingQuery',
                meta: {title: '调拨申请查询', isTag: true, isMenu: true, searchable: true}
            },
            // dbd
            {
                path: 'diao-bo-dan-list',
                component: () => import('@/view/hlgl/dbd/DiaoBoDanList'),
                name: 'DiaoBoDanList',
                meta: {title: '调拨单', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'diao-bo-dan-query',
                component: () => import('@/view/hlgl/dbd/DiaoBoDanQuery'),
                name: 'DiaoBoDanQuery',
                meta: {title: '调拨单查询', isTag: true, isMenu: true, searchable: true}
            },




        ]
    },
]

export default {
    constantRoutes,
    dynamicRoutes: HLGL_ROUTES
}
