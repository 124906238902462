import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const MDGL_ROUTES = [
    {
        path: '/mdgl',
        name: 'Mdgl',
        component: Layout,
        meta: {title: '门店管理', isMenu: true, searchable: true},
        redirect: '/mdgl/men-dian-xx-list',
        children: [
            {
                path: 'men-dian-xx-list',
                component: () => import('@/view/mdgl/mdxx/MenDianXXList'),
                name: 'MenDianXXList',
                meta: {title: '门店信息', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'men-dian-xx-query',
                component: () => import('@/view/mdgl/mdxx/MenDianXXQuery'),
                name: 'MenDianXXQuery',
                meta: {title: '门店信息查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'men-dian-hang-ye-list',
                component: () => import('@/view/mdgl/mdhy/MenDianHangYeList'),
                name: 'MenDianHangYeList',
                meta: {title: '门店行业', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'men-dian-hang-ye-query',
                component: () => import('@/view/mdgl/mdhy/MenDianHangYeQuery'),
                name: 'MenDianHangYeQuery',
                meta: {title: '门店行业查询', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'men-dian-dian-zhang-list',
                component: () => import('@/view/mdgl/mdxx/MenDianDianZhangList'),
                name: 'MenDianDianZhangList',
                meta: {title: '门店店长', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'xiao-shou-tong-ji-list',
                component: () => import('@/view/mdgl/xstj/XiaoShouTongJiList'),
                name: 'XiaoShouTongJiList',
                meta: {title: '销售统计', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'tong-ji-bao-biao-list',
                component: () => import('@/view/mdgl/tjbb/TongJiBaoBiaoList'),
                name: 'TongJiBaoBiaoList',
                meta: {title: '统计报表', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'ku-cun-bao-biao-tj',
                component: () => import('@/view/mdgl/tjbb/KuCunBaoBiaoTongJi'),
                name: 'KuCunBaoBiaoTongJi',
                meta: {title: '库存报表统计', isTag: true, isMenu: true, searchable: true}
            },
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: MDGL_ROUTES
}
