<script>
    export default {
        name: "XSearchItem",
        props: {
            label: {
                type: String,
                required: true
            },
            textWidth: {
                type: String,
                default: '138px'
            },
            lableWidth: {
                type: String,
                default: '115px'
            },
            marginRight: {
                type: String,
                default: '5px'
            },
            hasEnd: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            createDiv(divClass, style, slot) {
                return (<div class={divClass} style={style}>{slot}</div>);
            }
        },
        render(h, ctx) {
            const textStyle = {width: this.textWidth, marginRight: this.marginRight};
            const lableStyle = {width: this.lableWidth};
            const divClass = "x-search-text";
            return (<div class="x-search-item">
                <div class="x-search-label" style={lableStyle}>{this.label}</div>
                {Object.keys(this.$slots).map(slot => this.$slots[slot] && this.createDiv(divClass, textStyle, this.$slots[slot]))}
            </div>)
        }
    }
</script>

<style scoped>

</style>
