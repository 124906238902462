<script>
import {getDictType, initDictByType} from "@/util/dict"

export default {
    name: "XDictCascaderShow",
    data() {
        return {
            dict: getDictType(this.dictType)
        }
    },
    computed: {
        names: function () {
            if (!this.code) return this.placeholder;
            if (this.code.indexOf(',') > -1) {
                let code = this.code.split(',');
                let names = [];
                code.forEach((c) => {
                    names.push(this.getName(this.dict, c));
                })
                return names.join(',');
            } else {//单选
                return this.getName(this.dict, this.code);
            }
        }
    },
    created() {
        initDictByType(this.dictType);
    },
    props: {
        dictType: {
            type: String,
            required: true,
        },
        code: {
            type: String
        },
        placeholder: {
            type: String,
            default: '&nbsp'
        },
        tag: {
            type: String,
            default: 'span'
        }
    },
    methods: {
        getName(data, code) {
            let name = '';
            if (data && data.length > 0) {
                data.forEach((obj) => {
                    if (obj.key === code) {
                        name = obj.value.name;
                    }
                })
            }
            return name;
        }
    },
    render(h, ctx) {
        if (this.$scopedSlots && this.$scopedSlots.default) {
            return h(this.tag, {}, this.$scopedSlots.default({text: this.names}));
        }
        return h(this.tag, {domProps: {innerHTML: this.names}});
    }
}
</script>

<style scoped>

</style>
