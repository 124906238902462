<template>
    <transition
        name="dialog-fade"
        @after-enter="afterEnter"
        @after-leave="afterLeave">
        <div
            v-show="visible"
            class="el-dialog__wrapper"
            @click.self="handleWrapperClick">
            <div
                role="dialog"
                v-if="visibleWithDestroy"
                aria-modal="true"
                :aria-label="title || 'dialog'"
                :class="['el-dialog', { 'is-fullscreen': fullscreen, 'el-dialog--center': center }, customClass]"
                ref="dialog"
                :style="style">
                <div class="el-dialog__header">
                    <slot name="title">
                        <span class="el-dialog__title">{{ title }}</span>
                    </slot>
                    <button
                        type="button"
                        class="el-dialog__headerbtn"
                        aria-label="Close"
                        v-if="showClose"
                        @click="handleClose">
                        <i class="el-dialog__close el-icon el-icon-close"></i>
                    </button>
                </div>
                <template v-if="height">
                    <div class="el-dialog__body normal" v-if="rendered">
                        <el-scrollbar v-if="rendered" :noresize="!resize" class="default-scrollbar"
                                      wrap-class="default-scrollbar__wrap" view-class="default-scrollbar__view"
                                      :style="{height: height + 'px'}">
                            <slot></slot>
                        </el-scrollbar>
                    </div>
                </template>
                <template v-else>
                    <div class="el-dialog__body" v-if="rendered">
                        <slot></slot>
                    </div>
                </template>
                <div class="el-dialog__footer" v-if="$slots.footer || footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ElDialog from 'element-ui/lib/dialog';
import {all} from "deepmerge";

const dialogDestroyByVIf = all(
    [ElDialog,
        {
            watch: {
                visible(val) {
                    if (val) {
                        this.visibleWithDestroy = true
                        this.closed = false
                        this.$emit('open')
                        this.$el.addEventListener('scroll', this.updatePopper)
                        this.$nextTick(() => {
                            this.$refs.dialog.scrollTop = 0
                        })
                        if (this.appendToBody) {
                            document.body.appendChild(this.$el)
                        }
                    } else {
                        this.$el.removeEventListener('scroll', this.updatePopper)
                        if (!this.closed) this.$emit('close')
                        if (this.destroyOnClose) {
                            this.$nextTick(() => this.visibleWithDestroy = false)
                        }
                    }
                },
            }
        }], {clone: true});

//此组件用于解决El-dialog在destroy-on-close=true时的"destroy了当前组件(子)并同时创建了一个新的组件(子)"问题，
// 将原生使用"key"属性来实现destroy的方式，改为使用v-if实现。保证close时正确执行销毁
export default {
    mixins: [dialogDestroyByVIf],
    name: "XDialog",
    data() {
        return {
            closed: false,
            visibleWithDestroy: true,
        }
    },
    props: {
        resize: {
            type: Boolean,
            default: false
        },
        height: {
            type: [String, Number],
        },
        footer: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>
