import deepmerge from "deepmerge";
import {isFunction} from 'min-dash'

export function deepCopy(obj, cache) {
    if (cache === void 0) cache = [];

    // just return if obj is immutable value
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    // if obj is hit, it is in circular structure
    var hit = find(cache, function (c) {
        return c.original === obj;
    });
    if (hit) {
        return hit.copy
    }

    var copy = Array.isArray(obj) ? [] : {};
    // put the copy into cache at first
    // because we want to refer it in recursive deepCopy
    cache.push({
        original: obj,
        copy: copy
    });

    Object.keys(obj).forEach(function (key) {
        copy[key] = deepCopy(obj[key], cache);
    });

    return copy
}

export function find(arr, predicate, ctx) {
    for (let i = 0, len = arr.length; i < len; i++) {
        if (predicate.call(ctx, arr[i], i, arr)) return arr[i]
    }
    return undefined
}

export function isNotEmpty(array) {
    return Array.isArray(array) && array.length > 0
}

export function remove(array, element) {
    if (Array.isArray(array) && array.length) {
        const index = array.indexOf(element);
        array.splice(index, 1);
        return true;
    }
    return false;
}

export function pushIfAbsent(array, elementToAdd, equals) {
    if (Array.isArray(array)) {
        if (isFunction(equals)) {
            if (!array.some(ele => equals(ele, elementToAdd))) {
                array.push(elementToAdd);
                return true;
            }
        } else {
            if (!array.includes(elementToAdd)) {
                array.push(elementToAdd);
                return true;
            }
        }
    }
    return false;
}

export function isBlank(str) {
    if (typeof str === 'number') {
        return false;
    }
    return !str;
}

export function doNothing() {
}

export function isObject(obj) {
    return obj !== null && typeof obj === 'object'
}

//copy from vue/shared/util#looseEquals
export function deepEquals(a, b) {
    //undefined,null
    if (a === b) return true
    const isObjectA = isObject(a)
    const isObjectB = isObject(b)
    if (isObjectA && isObjectB) {
        try {
            const isArrayA = Array.isArray(a)
            const isArrayB = Array.isArray(b)
            if (isArrayA && isArrayB) {
                return a.length === b.length && a.every((e, i) => {
                    return deepEquals(e, b[i])
                })
            } else if (a instanceof Date && b instanceof Date) {
                return a.getTime() === b.getTime()
            } else if (!isArrayA && !isArrayB) {
                const keysA = Object.keys(a)
                const keysB = Object.keys(b)
                return keysA.length === keysB.length && keysA.every(key => {
                    return deepEquals(a[key], b[key])
                })
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    } else if (!isObjectA && !isObjectB) {
        return String(a) === String(b)
    } else {
        return false
    }
}

/**
 *  Inspired by element-ui's utils/util/getValueByPath
 */
export const setValueByPath = function (object, prop, value) {
    prop = prop || '';
    const paths = prop.split('.');
    let current = object;
    for (let i = 0, j = paths.length; i < j; i++) {
        const path = paths[i];
        if (!current || !current.hasOwnProperty(path)) {
            return;
        }

        if (i === j - 1) {
            current[path] = value;
            return;
        }
        current = current[path];
    }
};

export const roundNumber = function (num, limit) {
    if (limit > 0) {
        let a = Math.pow(10, limit)
        return Math.round(num * a) / a;
    } else {
        return Math.round(num);
    }
}

export function deepMerge(...obj) {
    return deepmerge.all(obj, {clone: true});
}

export const CustomMath = {
    getFloat(n) {
        if (isNaN(n) || n === "") return 0
        return parseFloat(n)
    },
    add2: function (...number) {
        return this.add(2, ...number);
    },
    add3: function (...number) {
        return this.add(3, ...number);
    },
    add6: function (...number) {
        return this.add(6, ...number);
    },
    add: function (scale, ...number) {
        if (isNaN(scale)) scale = 6;
        return roundNumber(number.reduce((prev, curr) => this.getFloat(prev) + this.getFloat(curr), 0), scale);
    },
    multiply: function (scale, ...number) {
        if (isNaN(scale)) scale = 6;
        return roundNumber(number.reduce((prev, curr) => this.getFloat(prev) * this.getFloat(curr), 1), scale);
    },
    multiply2: function (...number) {
        return this.multiply(2, ...number);
    },
    multiply3: function (...number) {
        return this.multiply(3, ...number);
    },
    multiply6: function (...number) {
        return this.multiply(6, ...number);
    },
    sub2: function (...number) {
        return this.sub(2, ...number);
    },
    sub3: function (...number) {
        return this.sub(3, ...number);
    },
    sub2ZK: function (...number) {
        return this.subZK(2, ...number);
    },
    sub6: function (...number) {
        return this.sub(6, ...number);
    },
    sub: function (scale, ...number) {
        if (isNaN(scale)) scale = 6;
        return roundNumber(number.reduce((prev, curr) => this.getFloat(prev) - this.getFloat(curr)), scale);
    },
    subZK: function (scale, ...number) {
        if (isNaN(scale)) scale = 6;
        return roundNumber(number.reduce((prev, curr) => this.getFloat(prev) + this.getFloat(curr)), scale);
    },
    division2: function (...number) {
        return this.division(2, ...number);
    },
    division3: function (...number) {
        return this.division(3, ...number);
    },
    division6: function (...number) {
        return this.division(6, ...number);
    },
    division: function (scale, ...number) {
        if (isNaN(scale)) scale = 6;
        return roundNumber(number.reduce((prev, curr) => this.getFloat(prev) / parseFloat(curr ? curr : 1)), scale);
    },
    divisionFloor: function (...number) {
        let v = this.division(6, ...number);
        return Math.floor(v);
    },
    zheKouToJinE: function (jiaGe, zheKou) {
        //处理价格
        let jg = parseFloat(jiaGe);
        let zk = parseInt(zheKou);
        zk = Math.max(1, Math.min(zheKou, 99)); //处理区间 1折 到99折
        if (zk === 10) {
            return jiaGe;
        } else if (zk > 10) {
            return jiaGe * zk / 100;
        } else {
            return jiaGe * zk * 10 / 100;
        }
    },
    zheKouFromJinE: function (jiaGe, zheHouJinE) {
        let jg = parseFloat(jiaGe);
        let zhje = parseFloat(zheHouJinE);
        let jinE = Math.max(Math.min(jg, zhje), jg * 10 / 100);
        let zheKou = Math.round(jinE / jg * 100);
        if (zheKou % 10 === 0) {
            zheKou = zheKou / 10;
        }
        return {jinE, zheKou};
    },
    min: function (...values) {
        let number = values.map(o => Number(o));
        return Math.min(...number);
    },
    max: function (...values) {
        let number = values.map(o => Number(o));
        return Math.max(...number);
    },
    gt: function (value1, value2) {
        let v1 = Number(value1);
        let v2 = Number(value2);
        return v1 > v2;
    },
    gte: function (value1, value2) {
        let v1 = Number(value1);
        let v2 = Number(value2);
        return v1 >= v2;
    },
    lte: function (value1, value2) {
        let v1 = Number(value1);
        let v2 = Number(value2);
        return v1 <= v2;
    },
    lt: function (value1, value2) {
        let v1 = Number(value1);
        let v2 = Number(value2);
        return v1 < v2;
    },
    eq: function (value1, value2) {
        let v1 = Number(value1);
        let v2 = Number(value2);
        return v1 === v2;
    },
    ne: function (value1, value2) {
        let v1 = Number(value1);
        let v2 = Number(value2);
        return v1 !== v2;
    }
}

export function copyProps(source, dest = {}, props) {
    if (props && props.length) {
        props.forEach(prop => dest[prop] = source[prop])
    }
    return dest;
}

/**
 * 毫秒转化时长
 * @param v 毫秒值
 * @returns {string}
 */
export function shiChangConvert(v) {
    let ms = parseInt(v)
    let hours = _setBl(parseInt((ms / (1000 * 60 * 60))));
    let minutes = _setBl(parseInt((ms % (1000 * 60 * 60)) / (1000 * 60)));
    let seconds = _setBl(Math.round((ms % (1000 * 60)) / 1000));
    return `${hours || 0}:${minutes || 0}:${seconds || 0}`
}

function _setBl(v) {
    return v < 10 ? `0${v}` : v
}

/**
 *
 * @param str 字符串
 * @param n 不足几位
 * @returns {string}
 */
export function fillZero(str, n) {
    return (Array(n).join(0) + str).slice(-n);
}

/**
 * 字段汇总
 * @param arr
 * @param keys
 * @returns {string}
 */
export function columnTotal(arr, keys) {
    let total = {}
    arr.forEach(d => {
        keys.forEach(key => {
            total[key] = CustomMath.add6(total[key] || 0, d[key] || 0)
        })
    });
    return total;
}

export function numberToCurrencyNo(value) {
    if (!value && isNaN(value)) return 0;
    // 获取整数部分
    const intPart = Math.trunc(value);
    // 整数部分处理
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    // 处理小数部分
    let floatPart = '';
    // 将数值截取为小数部分和整数部分
    const valueArray = value.toString().split('.');
    if (valueArray.length === 2) {
        floatPart = '.' + valueArray[1].toString()
    }
    return intPartFormat + floatPart;
}

export function numberSmallToBig(value, cnIntLast = '', cnInteger = '') {
    // 汉字的数字
    const cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    // 基本单位
    const cnIntRadice = ["", "拾", "佰", "仟"];
    // 对应整数部分扩展单位
    const cnIntUnits = ["", "万", "亿", "兆"];
    // 对应小数部分单位
    const cnDecUnits = ["角", "分"];
    // 最大处理的数字
    const maxNum = 9999999999999999.99;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = "";
    // 分离金额后用的数组，预定义
    let parts;
    if (value === "") {
        return "";
    }
    value = parseFloat(value);
    if (value >= maxNum) {
        // 超出最大处理数字
        return "";
    }
    if (value === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    if (value < 0) {
        value *= -1;
    }
    // 转换为字符串
    value = value.toString();
    if (value.indexOf(".") === -1) {
        integerNum = value;

        decimalNum = "";
    } else {
        parts = value.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            const n = integerNum.substr(i, 1);
            const p = IntLen - i - 1;
            const q = p / 4;
            const m = p % 4;
            if (n === "0") {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                // 归零
                zeroCount = 0;
                //alert(cnNums[parseInt(n)])
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== "") {
        const decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            const n = decimalNum.substr(i, 1);
            if (n !== "0") {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === "") {
        chineseStr += cnInteger;
    }
    return chineseStr;
}

export function clearProps(target, ...keys) {
    if (target && keys && keys.length > 0) {
        let tkeys = Object.keys(target);
        keys.forEach(key => tkeys.indexOf(key) > -1 && (target[key] = ""));
    }
}

export function resetProps(target, str = '') {
    target && Object.keys(target).forEach(key => target[key] = str);
}

export function copyPropsExist(target, source) {
    if (target && source) {
        let tkeys = Object.keys(target);
        Object.keys(source).forEach(key => tkeys.indexOf(key) > -1 && (target[key] = (source[key] || "")));
    }
}

export function formatNumberQFW(value, places = 2){
    let text = value ? parseFloat(value).toFixed(places) : parseFloat("0").toFixed(places);
    return text.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}