import {currentPageHref, currentPageKey, isInSubsystem, PageSelector} from '@/pages/pages';
import {getMyRouteMenus, getMySubsystems} from "@/service/system/menu";
import {
    checkComponentNameSameWithRoute,
    filterAsyncRouter,
    findActualRouter,
    findFlattedRedirectOnlyRoutes,
    mergeRoutes,
    naturalOrderComparator
} from '@/router/helper';
import {postConstantRoutes, routerFactory} from '@/router/index';
import {isObject} from 'min-dash'

const pageRoutesModuleSuffix = isInSubsystem ? `/modules/${currentPageKey}` : '';
const pageRoutes = require(`../../router${pageRoutesModuleSuffix}`).default;

let constantRoutes, dynamicRoutes;
if (isObject(pageRoutes)) {
    dynamicRoutes = pageRoutes.dynamicRoutes || [];
    constantRoutes = pageRoutes.constantRoutes || [];
} else {
    constantRoutes = [];
    dynamicRoutes = pageRoutes;
}

//校验组件名称与route内的name声明是否一致
if (process.env.NODE_ENV !== 'production') {
    checkComponentNameSameWithRoute(constantRoutes.concat(dynamicRoutes));
}

const permission = {
    state: {
        routes: [],//routes of current subsystem/page
        routesWithoutMenuOnly: [],
        subsystemRoutes: [],
        initialized: false
    },
    mutations: {
        SET_ROUTES: (state, {routes, routesWithoutMenuOnly}) => {
            state.routes = mergeRoutes(constantRoutes, routes);
            state.routesWithoutMenuOnly = mergeRoutes(findActualRouter(constantRoutes), routesWithoutMenuOnly);
        },
        SET_SUBSYSTEM_ROUTES: (state, subsystemRoutes) => state.subsystemRoutes = subsystemRoutes,
        SET_INITIALIZED_FLAG: (state, initialized) => state.initialized = initialized
    },
    actions: {
        // 生成路由
        async initializeRoutes({dispatch, commit}) {
            let uncompletedCount = 2;
            await new Promise((resolve, reject) => {
                const completedCounter = () => --uncompletedCount === 0 && resolve();
                dispatch('initializeSubsystemRoutes').then(completedCounter).catch(reject);
                if (isInSubsystem) {
                    dispatch('initializeRoutesWithinSubsystem').then(completedCounter).catch(reject);
                } else {
                    completedCounter();
                }
            });
            await commit('SET_INITIALIZED_FLAG', true)
        },
        initializeRoutesWithinSubsystem({commit}) {
            return getMyRouteMenus(currentPageHref).then(({data}) => {
                let accessibleRoutesWithoutMenuOnly;
                let accessibleRoutes;
                if (data && data.length) {
                    let routeMap = new Map();
                    data.forEach(element => {
                        if (element.url) {
                            if (routeMap.has(element.url) && window.console) {
                                console.error('duplicated path [' + element.url + ']! please check it');
                            }
                            routeMap.set(element.url, element);
                        }
                    });

                    accessibleRoutes = filterAsyncRouter(dynamicRoutes, routeMap);
                    const flattedRedirectOnlyRoutes = findFlattedRedirectOnlyRoutes(accessibleRoutes);
                    accessibleRoutesWithoutMenuOnly = findActualRouter(accessibleRoutes).concat(flattedRedirectOnlyRoutes);

                    if (routeMap.size > 0 && window.console) {
                        for (let key of routeMap.keys()) {
                            console.debug(`unmatched menu path in backend: [${key}], please check it`);
                        }
                    }
                } else {
                    accessibleRoutesWithoutMenuOnly = [];
                    accessibleRoutes = [];
                }

                //兜底路由
                accessibleRoutesWithoutMenuOnly = [...accessibleRoutesWithoutMenuOnly, ...postConstantRoutes];
                commit('SET_ROUTES', {
                    routes: accessibleRoutes,
                    routesWithoutMenuOnly: accessibleRoutesWithoutMenuOnly
                });
                return accessibleRoutesWithoutMenuOnly;
            });
        },
        initializeSubsystemRoutes({commit}) {
            return getMySubsystems().then(({data}) => {
                if (data && data.length) {
                    let routeMap = new Map();
                    data.forEach(item => routeMap.set(item.url, item));
                    const subsystemRoutes = PageSelector.filter(item => routeMap.has(item.href))
                        .map(item => {
                            const {name, sort} = routeMap.get(item.href);
                            return {...item, name, meta: {sort}};
                        })
                        .sort(naturalOrderComparator);

                    commit('SET_SUBSYSTEM_ROUTES', subsystemRoutes);
                    return subsystemRoutes;
                }
            });
        }
    }
};

export default permission;
