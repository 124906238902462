import request from '@/util/request'

export function mainTree() {
    return request({
        url: '/menu/main-tree',
        method: 'get'
    });
}

export function menuTreeForRoleEdit() {
    return request({
        url: '/menu/tree-for-role-edit',
        method: 'get'
    });
}

export function add(data) {
    return request({
        url: '/menu/add',
        method: 'post',
        data
    });
}

export function getUpdate(id) {
    return request({
        url: '/menu/getUpdate/' + id,
        method: 'get'
    });
}

export function update(data) {
    return request({
        url: '/menu/update',
        method: 'post',
        data
    });
}

export function del(id) {
    return request({
        url: '/menu/del/' + id,
        method: 'get'
    });
}

export function changeMenuParent(data) {
    return request({
        url: '/menu/change-menu-parent',
        method: 'post',
        data
    });
}

export function getMyRouteMenus(subsystemUrl) {
    return request({
        url: `/menu/my-route-menus?subsystemUrl=${subsystemUrl}`,
        method: 'get',
    });
}

export function getMySubsystems() {
    return request({
        url: '/menu/my-subsystems',
        method: 'get'
    });
}
