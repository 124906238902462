import request from '@/util/request'

export function getRegisteredTenantOptions() {
    return request({
        url: '/admin/registered-tenants',
        method: 'get'
    })
}

export function login(data) {
    return request({
        url: '/admin/sign-in',
        method: 'post',
        data
    })
}

export function getSupportedDbTypeDictList() {
    return request({
        url: '/admin/supported-db-type-dict',
        method: 'get'
    })
}

export function createTenant(sessionId, data) {
    return request({
        url: '/admin/tenant',
        method: 'put',
        headers: {Authorization: sessionId},
        data
    })
}

export function getRegisteredTenantList(sessionId) {
    return request({
        url: '/admin/tenants',
        headers: {Authorization: sessionId},
        method: 'get'
    })
}

export function checkSessionid(sessionId) {
    return request({
        url: '/admin/check-sessionid',
        method: 'get',
        headers: {Authorization: sessionId},
    })
}