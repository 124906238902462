import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout/Layout'

Vue.use(VueRouter);

export const guide = {
    path: '*',
    name: 'GuideJL',
    component: () => import('@/view/GuideJL'),
    meta: {title: '导航', isMenu: false, isTag: false, searchable: false}
}

/**
 * sideBar中的菜单项受后台配置影响的的属性包括：icon, name(title), sort, description。
 * 菜单配置时需注意url与router中的path一致，否则匹配不上时将不会显示；同时sort完全取决于后台配置；icon优先取后台配置，无则使用前端配置。
 *
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb，如果设置值为noRedirect则不允许在面包屑中点击进行跳转
 * name:'router-name'             // 设定路由的名称，一定要填写并与组件(也必须设定)内的name一致，且保证全局(或一个子系统内)唯一，不然使用<keep-alive>时会出现各种问题：如缓存不生效
 * meta : {
    noCache: true,                               // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)，不想被缓存的组件需设置为true
    title: 'title',                              // 设置该路由在侧边栏和面包屑中默认展示的名字，动态路由会被后台获取的menu的该属性覆盖
    icon: 'el-icon-platform-eleme',             // 设置该路由的图标，对应elementUI的icon class，动态路由会被后台获取的menu的该属性覆盖
    affix: true,                                //  设置是否不可移除，为true时，不可移除，如首页。默认false
    searchable: true,                           //  设置是否可搜索，为true时可以通过页面右上角搜索功能搜索并进入，默认true
    breadcrumb: false,                          // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: 'active-menu-name'              //如果设置了path，侧边栏将突出显示设置的路径。如编辑页面设置activeMenu为list页
    menuOnly: false                             //默认false，设置为true表示仅作为菜单展示项，不会映射到真正的路由表，用于需要在菜单中显示层级关系，又不对其他功能(组件缓存，路由匹配)产生影响时
  }
 */
export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        meta: {noCache: true},
        children: [
            {
                path: '/redirect/:path(.*)',
                name: 'Redirect',
                component: () => import('@/view/redirect')
            }
        ]
    },
    {
        path: '/login',
        name: 'LoginJL',
        component: () => import('@/view/LoginJL'),
        meta: {title: '登录', isMenu: false, isTag: false, searchable: false},
        props: route => ({redirect: route.query.redirect})
    },
    //{...guide, path: '/guideJL'},
    {
        path: '/guide',
        name: 'Guide',
        component: () => import('@/view/Guide'),
        meta: {title: '导航', isMenu: false, isTag: false, searchable: false},
    },
    {
        path: '/admin',
        name: 'MultiTenantAdminLogin',
        component: () => import('@/view/manage/multi-tenant/multi-tenant-admin-login'),
        meta: {title: '分发系统管理平台登录', isMenu: false, isTag: false, searchable: false},
    },
    {
        path: '/admin/tenant-manage/:sessionId',
        name: 'MultiTenantManageList',
        component: () => import('@/view/manage/multi-tenant/manage-list'),
        meta: {title: '分发系统管理平台', isMenu: false, isTag: false, searchable: false},
        props: true
    },
];

//兜底的路由
export const postConstantRoutes = [
    guide
];

export const routerFactory = function (otherRoutes = []) {
    return new VueRouter({
        scrollBehavior: () => ({y: 0}), // 跳转路由时 页面竖向回到顶部
        routes: [...constantRoutes, ...otherRoutes],
    });
};

const router = routerFactory(postConstantRoutes);
export default router;
