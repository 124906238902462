<template>
    <div>
        <div @click="open">
            <slot>
                <el-button type="info" size="mini" round>
                    导出
                </el-button>
            </slot>
        </div>
        <el-dialog ref="eld" title="选择导出字段" :visible.sync="show" @close="cancel" :close-on-click-modal="false"
                   destroy-on-close center append-to-body width="500" height="400">
            <div style="padding-bottom: 5px">
                <el-button type="primary" size="mini" @click="checkAll">全 选</el-button>
                <el-button type="warning" size="mini" @click="checkNotAll">全不选</el-button>
            </div>
            <div>
                <el-col v-for="item in Object.keys(checkItems)" :key="item" :span="4">
                    <el-checkbox v-model="checkedKeys" :label="item" :key="item">{{ checkItems[item] }}</el-checkbox>
                </el-col>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="exportExcel" size="mini" icon="el-icon-document-add">导出当前</el-button>
                <el-button v-if="exportService" type="primary" size="mini" @click="exportAll"
                           icon="el-icon-document-add">导出全部</el-button>
                <el-button @click="cancel" size="mini" icon="el-icon-switch-button">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {export_json_to_excel} from "./js/Export2Excel"
    import {formatDate} from "@/util/dateUtil";
    import {deepCopy} from "@/util/objects";
    import {getDictType, getName, initDictByType} from "@/util/dict";
    // import {getUpdate} from "@/service/base/KuQuXX";
    import {LOCAL_STORAGE} from "@/util/storage";

    const lens = {
        //入库单号转换
        crkdm: {len: 12, headStart: 0, headEnd: 2, timeStart: 2, timeEnd: 8, numberStart: 8, numberEnd: 12},
        //结算单号
        crkjs: {len: 33, timeStart: 21, timeEnd: 29, numberStart: 29, numberEnd: 33},
        //合同号
        hth: {len: 35, headStart: 21, headEnd: 25, timeStart: 25, timeEnd: 31, numberStart: 31, numberEnd: 35},
        //计划单号
        jhdh: {len: 25, yearStart: 18, yearEnd: 22, numberStart: 22, numberEnd: 25},
        //计划明细单号
        jhmxdh: {len: 50, yearStart: 18, yearEnd: 22, midStart: 22, midEnd: 25, numberStart: 46, numberEnd: 50},
    }
    const arr = new Array("LIST", "DM", "FMT", "YHZH");
    export default {
        name: "ExcelExport",
        data() {
            return {
                //选中key
                checkedKeys: [],
                //字段选择弹窗
                show: false,
                //选择字段
                checkItems: {},
                exportData: [],
                connector: "-",
                kqmc: '',
                fmts: [],//数字格式化
                cellStyle: []//单元格样式
            }
        },
        created() {
            // initDictByType()
            // getUpdate().then(res => {
            //     this.kqmc = res.data.kqmc;
            // })
        },
        props: {
            //导出数据列表
            tableData: {
                type: Array,
                required: true
            },
            /* 导出表头及字段key,字典字段传入字典类型 例:{
                  shangPinSkuMa:'原材料编码',
                  shangPinSkuMC:'原材料名称',
                  shangPinJC:'商品简称',
                  jiLiangDW:{
                      name:'计量单位',
                      type:"T_YW_SP_JLDW",// type:"DM"代码格式化,"FMT":自定义函数格式化,"LIST":动态数组数值填充,"YHZH":银行账号
                  },
                  kuCunSL:{
                      name:'计量单位',
                      fmt:"#,##0.00" //数字格式化,值要为数字
                      cellStyle: {
                                    font: {
                                        name: "宋体",//字体
                                        sz: 9,//字号
                                    },
                                    alignment: {
                                        horizontal: 'left',//水平
                                        vertical: 'center',//垂直
                                        indent: 0//缩进
                                    }
                                 }
                  }
            };*/
            headAndKey: {
                type: Object,
                required: true,
            },
            //文件名
            fileName: {
                type: String,
                default: '未命名'
            },
            //sheet名称
            sheetName: {
                type: String,
                default: 'sheet1'
            },
            //全部导出方法
            exportService: {
                type: Function
            },
            //导出条件
            search: {
                type: Object,
                default() {
                    return {}
                }
            },
            isSum: {
                type: Boolean,
                default: false
            },
            condition: {
                type: Array,
                default() {
                    return []
                }
            },
            isIndex: {
                type: Boolean,
                default: true
            },
        },
        watch: {
            "tableData": {
                handler: function (n) {
                    this.exportData = deepCopy(n)
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            //初始化表头
            initHeadAndKey() {
                this.checkItems = {}
                //获取浏览器缓存选择项
                let checkLocal = LOCAL_STORAGE.get(this.fileName);
                if (checkLocal) {
                    //获取缓存项
                    this.checkedKeys = checkLocal;
                } else {
                    //获取表头设置项
                    this.checkedKeys = Object.keys(this.headAndKey);
                }
                //选定表头赋值
                for (let item in this.headAndKey) {
                    //如果是对象
                    if (this.headAndKey.hasOwnProperty(item) && this.headAndKey[item] instanceof Object ) {
                        if (this.headAndKey[item].type === arr[0]){
                            let list = this.headAndKey[item].header;
                            list.forEach((name, index) => {
                                this.checkItems['list' + index] = name;
                            })
                        }else {
                            this.checkItems[item] = this.headAndKey[item].name
                        }
                    } else {
                        this.checkItems[item] = this.headAndKey[item]
                    }
                }
            },
            open() {
                this.show = true;
                this.initHeadAndKey();
            },
            cancel() {
                this.show = false
            },
            //全部导出方法
            exportAll() {
                this.exportData=[]
                this.exportService(this.search).then(res => {
                    this.exportData = res.data || [];
                    this.exportExcel()
                })
            },
            //导出的方法
            exportExcel: function () {
                if (this.checkedKeys.length === 0) {
                    this.$message.warning("请选择导出字段")
                    return
                }
                //根据文件名称缓存选定表头
                LOCAL_STORAGE.set(this.fileName, this.checkedKeys);
                //获取所有header
                let header = this.getHeader()// 设置Excel的表格第一行的标题
                let filterVal = deepCopy(this.checkedKeys); // tableData里对象的属性key值
                this.isIndex && filterVal.unshift('index')
                //处理字典数据
                let data = []
                if (this.exportData) {
                    let tempData = deepCopy(this.exportData)
                    tempData.forEach((d, i) => {
                        //添加序号列
                        d.index = i + 1;
                        //添加合计列
                        if (this.isSum && this.exportData.length === i + 1) {
                            d.index = '合计';
                        }
                        let keys = Object.keys(d)
                        //遍历所有key
                        keys.forEach(key => {
                            d[key] = {value: d[key], fmt: "", cellStyle: {}}
                            //获取key的单元格设置
                            //如果单元格设置存在并且为对象
                            if (this.headAndKey.hasOwnProperty(key) && this.headAndKey[key] instanceof Object) {
                                //处理字典
                                if (this.headAndKey[key].type && arr.indexOf(this.headAndKey[key].type) === -1) {
                                    if (this.headAndKey[key].many) {
                                        //多选字典处理
                                        d[key].value = this.getNameMany(getDictType(this.headAndKey[key].type), d[key].value);
                                    } else {
                                        //单选字典数据
                                        d[key].value = getName(getDictType(this.headAndKey[key].type), d[key].value);
                                    }
                                }
                                //处理LIST
                                if (this.headAndKey[key].type === arr[0]) {
                                    for (let i = 0; i < d[key].value.length; i++) {
                                        if (this.checkItems['list' + i]) {
                                            d['list' + i] = {value: ""}
                                            d['list' + i].value = d[key].value[i];
                                        }
                                    }
                                }
                                //处理FMT
                                if (this.headAndKey[key].type === arr[2]) {
                                    d[key].value = this.headAndKey[key].format(d[n]);
                                }
                                //处理YHZH
                                if (this.headAndKey[key].type === arr[3]) {
                                    d[key].value = d[key].value.replace(/(.{4})/g, "$1 ");
                                }
                                //TODO 不清楚
                                if (this.headAndKey[key].hb && d[this.headAndKey[key].target]) d[key].value += '(' + getName(getDictType(this.headAndKey[key].hb), d[this.headAndKey[key].target]) + ')';
                                //处理代码
                                if (this.headAndKey[key].type === arr[1]) d[key].value = this.getDhDaiMa(d[key].value)
                                //处理数字格式化
                                if (this.headAndKey[key].fmt) d[key].fmt = this.headAndKey[key].fmt
                                //处理单元格样式
                                if (this.headAndKey[key].cellStyle) d[key].cellStyle = this.headAndKey[key].cellStyle
                            }

                        })
                    })
                    //转换数据为二维数组
                    data = this.formatJson(filterVal, tempData);
                }
                //导出excel
                export_json_to_excel({
                    header,//表头
                    data,//数据
                    autoWidth: true,//是否自适应宽度
                    bookType: 'xlsx',//文件格式
                    sheetName: this.sheetName,//sheet名称
                    filename: `${this.fileName}${formatDate(new Date(), "yyyyMMddhhmmss")}`,//文件名称:例-文件名20220225110133
                    titleName: this.kqmc + this.fileName,
                    condition: this.condition
                });//最后一个是表名字
                this.cancel()
            },
            getNameMany(data, code) {
                let name = '';
                if (data && data.length > 0) {
                    data.forEach((obj) => {
                        if (obj.key === code) {
                            name = obj.value.name;
                        }
                    })
                }
                return name;
            },
            //获取所有header
            getHeader() {
                let header = this.isIndex ? ['序号'] : []
                this.checkedKeys.forEach(k => {
                    header.push(this.checkItems[k])
                })
                return header;
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => v[j]))
            },
            getDhDaiMa(dm) {
                //入库单号转换
                if (dm.length === lens.crkdm.len) {
                    let head = dm.substring(lens.crkdm.headStart, lens.crkdm.headEnd);
                    let time = dm.substring(lens.crkdm.timeStart, lens.crkdm.timeEnd);
                    let number = dm.substring(lens.crkdm.numberStart, lens.crkdm.numberEnd);
                    return head.concat(this.connector).concat(time).concat(this.connector).concat(number);
                }
                //结算单号
                if (dm.length === lens.crkjs.len) {
                    let time = dm.substring(lens.crkjs.timeStart, lens.crkjs.timeEnd);
                    let number = dm.substring(lens.crkjs.numberStart, lens.crkjs.numberEnd);
                    return time.concat(this.connector).concat(number);
                }
                //合同号
                if (dm.length === lens.hth.len) {
                    let head = dm.substring(lens.hth.headStart, lens.hth.headEnd);
                    let time = dm.substring(lens.hth.timeStart, lens.hth.timeEnd);
                    let number = dm.substring(lens.hth.numberStart, lens.hth.numberEnd);
                    return head.concat(this.connector).concat(time).concat(this.connector).concat(number);
                }
                //计划单号
                if (dm.length === lens.jhdh.len) {
                    let year = dm.substring(lens.jhdh.yearStart, lens.jhdh.yearEnd);
                    let number = dm.substring(lens.jhdh.numberStart, lens.jhdh.numberEnd);
                    return year.concat(this.connector).concat(number);
                }
                //计划明细单号
                if (dm.length === lens.jhmxdh.len) {
                    let year = dm.substring(lens.jhmxdh.yearStart, lens.jhmxdh.yearEnd);
                    let mid = dm.substring(lens.jhmxdh.midStart, lens.jhmxdh.midEnd);
                    let number = dm.substring(lens.jhmxdh.numberStart, lens.jhmxdh.numberEnd);
                    return year.concat(this.connector).concat(mid).concat(this.connector).concat(number);
                }
            },
            checkAll() {
                this.checkedKeys = Object.keys(this.checkItems);
            },
            checkNotAll() {
                this.checkedKeys = [];
            }
        }
    }
</script>

<style scoped>

</style>
