<template>
    <el-radio-group v-bind="$attrs" v-on="$listeners" :style="$vnode.data.style" :class="$vnode.data.class" :size="size">
        <el-radio-button v-for="d in dict" :key="uuid + d.key" :label="d.key" :disabled="disabledCode===d.key">{{d.value}}</el-radio-button>
    </el-radio-group>
</template>

<script>
    import {getDictType, initDictByType} from "@/util/dict"
    import {v4} from 'uuid';

    export default {
        name: "XRadioGroup",
        data() {
            return {
                dict: getDictType(this.dictType),
                uuid: v4()
            }
        },
        created() {
            initDictByType(this.dictType);
        },
        props: {
            dictType: {
                type: String,
                required: true,
            },
            disabledCode: {
                type: String,
                default: "",
            },
            size: {
                type: String,
                default: "small"
            }
        }
    }
</script>

<style scoped>

</style>