<template>
    <div class="x-detail-item">
        <div class="x-detail-label" :style="{width:getLabelWidth}">
            <slot name="label">{{ label }}</slot>
        </div>
        <div class="x-detail-text">
            <slot>
                {{ text }}
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    name: "XDetailItem",
    inject: ['xDetail'],
    computed: {
        getLabelWidth() {
            if (this.labelWidth) {
                return this.labelWidth
            } else {
                return this.xDetail.labelWidth
            }
        },
        text() {
            if (this.prop) {
                return this.xDetail.entity[this.prop];
            } else {
                return "";
            }
        }
    },
    props: {
        label: {
            type: String,
            default: ""
        },
        labelWidth: {
            type: String,
        },
        prop: {
            type: String,
        }
    }
}
</script>

<style scoped>

</style>
