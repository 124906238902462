import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const YXGL_ROUTES = [
    {
        path: '/yhq',
        name: 'yhq',
        component: Layout,
        meta: {title: '优惠券', isMenu: true, searchable: true},
        redirect: '/yhq/hui-yuan-you-hui-quan-list',
        children: [
            {
                path: 'hui-yuan-you-hui-quan-list',
                component: () => import('@/view/hygl/yhq/HuiYuanYouHuiQuanList'),
                name: 'HuiYuanYouHuiQuanList',
                meta: {title: '会员优惠券', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'you-hui-quan-fa-fang-ji-lu-list',
                component: () => import('@/view/hygl/yhqffjl/YouHuiQuanFaFangJiLuList'),
                name: 'YouHuiQuanFaFangJiLuList',
                meta: {title: '优惠券发放记录', isTag: true, isMenu: true, searchable: true}
            }
        ]
    },

]

export default {
    constantRoutes,
    dynamicRoutes: YXGL_ROUTES
}
