import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const SYSTEM_ROUTES = [
    {
        path: '/system',
        name: 'System',
        component: Layout,
        meta: {title: '系统管理', isMenu: true, searchable: true},
        redirect: '/system/organ',
        children: [
            {
                path: 'organ',
                component: () => import('@/view/system/organ/Organ'),
                name: 'Organ',
                meta: {title: '组织管理', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'user',
                component: () => import('@/view/system/user/UserList'),
                name: 'UserList',
                meta: {title: '用户管理', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'auto',
                component: () => import('@/view/system/auto/Auto'),
                name: 'Auto',
                meta: {title: '代码生成', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'audit',
                component: () => import('@/view/system/agency/AuditList'),
                name: 'AuditList',
                meta: {title: '审核代办', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'remind',
                component: () => import('@/view/system/agency/RemindList'),
                name: 'RemindList',
                meta: {title: '提醒', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'menu',
                component: () => import('@/view/system/menu/Menu'),
                name: 'Menu',
                meta: {title: '菜单管理', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'access-log',
                component: () => import('@/view/system/accessLog/AccessLogList'),
                name: 'AccessLogList',
                meta: {title: '日志管理', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'role',
                component: () => import('@/view/system/role/RoleList'),
                name: 'Role',
                meta: {title: '角色管理', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'auto',
                component: () => import('@/view/system/auto/Auto'),
                name: 'Auto',
                meta: {title: '代码生成', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'dict',
                component: () => import('@/view/system/dict/DictList'),
                name: 'DictList',
                meta: {title: '字典管理', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'user',
                component: () => import('@/view/system/user/UserList'),
                name: 'UserList',
                meta: {title: '用户管理', isTag: true, isMenu: true, searchable: true}
            }
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: SYSTEM_ROUTES
}
