import Layout from '@/components/layout/Layout'
import {CONSTANTROUTES} from "./constant-routes";

const constantRoutes = [{
    path: '/',
    name: 'Layout',
    component: Layout,
    meta: {isMenu: true},
    redirect: '/dashboard',
    children: [
        {
            path: 'dashboard',
            component: () => import('@/view/Home'),
            name: 'Dashboard',
            meta: {
                title: '首页',
                icon: 'el-icon-s-home',
                isTag: true,
                noCache: false,
                searchable: false,
                affix: true
            }
        }
    ]
},
    ...CONSTANTROUTES
]


const SBGL_ROUTES = [
    {
        path: '/sbgl',
        name: 'Sbgl',
        component: Layout,
        meta: {title: '设备管理', isMenu: true, searchable: true},
        redirect: '/sbgl/da-yin-ji-list',
        children: [
            {
                path: 'da-yin-ji-list',
                component: () => import('@/view/sbgl/dyj/DaYinJiList'),
                name: 'DaYinJiList',
                meta: {title: '打印机', isTag: true, isMenu: true, searchable: true}
            },
            {
                path: 'shou-yin-ji-list',
                component: () => import('@/view/sbgl/syd/ShouYinJiList'),
                name: 'ShouYinJiList',
                meta: {title: '收银机', isTag: true, isMenu: true, searchable: true}
            }
        ]
    }
]

export default {
    constantRoutes,
    dynamicRoutes: SBGL_ROUTES
}
