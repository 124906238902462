import path from 'path';
import {flatten, isFunction, isObject} from 'min-dash'

export function SuppressNavigationDuplicatedError(error) {
    // Ignore the vuex err regarding  navigating to the page they are already on.
    if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes('Avoided redundant navigation to current location')
    ) {
        // But rethrow any other errors
        throw error;
    }
}

export function findActualRouter(originRoutes = []) {
    return filterActualRoutes(originRoutes);
}

/**
 * 过滤真实路由并处理含有仅作为菜单的路由的路径，以适配element-ui的navMenu组件
 *
 * 如以下路由表：
 * <pre>{@code
const routes = [{
  path: '/route1',
  name: 'Route1',
  meta: {title: '路由一', menuOnly:true},
 children: [
 {
          path: '/route2',
          name: 'Route2',
          component: () => import('@/view/Route2'),
          meta: {title: '路由二'},
 },
 {
          path: '/route2',
          name: 'Route2',
          component: () => import('@/view/Route2'),
          meta: {title: '路由二'},
 }
 ]},
 {
          path: '/route3',
          name: 'Route3',
          component: () => import('@/view/Route3'),
          meta: {title: '路由三'},
}
 ]}</pre>

 会被转换为：
 * <pre>{@code
const routes = [
 {
          path: '/route1/route2',
          name: 'Route2',
          component: () => import('@/view/Route2'),
          meta: {title: '路由二', parent: MenuOnlyRoute1},
 },
 {
          path: '/route1/route2',
          name: 'Route2',
          component: () => import('@/view/Route2'),
          meta: {title: '路由二', parent: MenuOnlyRoute1},
 },
 {
          path: '/route3',
          name: 'Route3',
          component: () => import('@/view/Route3'),
          meta: {title: '路由三'},
 }
 ]}</pre>
 * @param routes    包含仅菜单路由的路由表
 * @param virtualPath   虚拟路径，为当前路由节点的父(包含所有祖先)节点的路径拼接而成
 * @param parentMenuOnly    父级是否为仅作为菜单节点
 * @returns {Array} 真实路由表
 */
function filterActualRoutes(routes, virtualPath = '', parentMenuOnly) {
    if (!routes || !routes.length) {
        return [];
    }
    let actualRoutes = [];
    let menuOnlyRoutes = [];
    for (let i = 0; i < routes.length; i++) {
        if (!routes[i]) {
            continue;
        }
        let ele = {...routes[i]};
        //绑定父路由(无视仅作为菜单的路由/实际路由)，用于breadcrumb的数据生成
        bindParent(ele);

        if (ele.meta && ele.meta.menuOnly) {
            menuOnlyRoutes.push(ele);
        } else {
            actualRoutes.push(ele);
        }
    }
    let containsChildren = ele => ele.children && ele.children.length;

    actualRoutes.filter(containsChildren)
        .forEach(ele => {
            let elePath = ele.path;
            ele.children = filterActualRoutes(ele.children, `${virtualPath}${insertSlashIfNotStartWith(elePath)}`, false);
        });

    //父级为仅菜单节点时则需要将其路径作为最终映射的路由路径前缀
    if (parentMenuOnly) {
        actualRoutes.forEach(ele => (ele.path = `${virtualPath}/${ele.path}`));
    }

    //将仅为菜单的节点其children层级向上提升至同级
    const childrenOfMenuOnlyRoutes = menuOnlyRoutes.filter(containsChildren)
        .map(ele => filterActualRoutes(ele.children, `${virtualPath}${insertSlashIfNotStartWith(ele.path)}`, true));

    return [...flatten(childrenOfMenuOnlyRoutes), ...actualRoutes];
}

function insertSlashIfNotStartWith(str) {
    return str.startsWith('/') ? str : `/${str}`;
}

function bindParent(route) {
    let children;
    if (route && (children = route.children) && children.length) {
        children.forEach(child => {
            let meta = child.meta || (child.meta = {});
            meta.parent = route;
        });
    }
}

/**
 * 取得扁平化的"仅重定向"路由列表，用于实现menuOnly的组件其redirect功能
 * @param routes    全部的路由
 * @param parentPath    父路径
 * @returns {Array} 扁平化的"仅重定向"路由列表
 */
export function findFlattedRedirectOnlyRoutes(routes, parentPath = '') {
    let redirectOnlyRoutes = [];
    if (routes && routes.length) {
        redirectOnlyRoutes = routes.filter(route => route.meta && route.meta.menuOnly)
            .map(({path, redirect}) => ({path: `${parentPath}${insertSlashIfNotStartWith(path)}`, redirect}));

        const redirectOnlyRoutesOfChildren = routes.filter(route => route.children && route.children.length)
            .map(({path, children}) => findFlattedRedirectOnlyRoutes(children, `${parentPath}${insertSlashIfNotStartWith(path)}`));
        redirectOnlyRoutes = redirectOnlyRoutes.concat(flatten(redirectOnlyRoutesOfChildren));
    }
    return redirectOnlyRoutes;
}

// 遍历后台传来的路由字符串，转换为组件对象
export function filterAsyncRouter(asyncRoutes = [], accessibleRouteMap, basePath = '') {
    if (asyncRoutes.length === 0 || !accessibleRouteMap || accessibleRouteMap.size === 0) {
        return [];
    }

    return asyncRoutes.filter(element => {
        const {children, path: elementPath, meta} = element;
        let fullPath = path.resolve(basePath, elementPath);
        //当前层级匹配
        let matched = true;
        if (meta.isMenu) {
            const routeMeta = accessibleRouteMap.get(fullPath);
            if (routeMeta) {
                accessibleRouteMap.delete(fullPath);
                //copy props
                let {name, icon, description, sort} = routeMeta;
                Object.assign(element, {meta: {...meta, title: name, icon, description, sort}});
            } else {
                matched = false;
            }
        }
        //或子级有匹配
        if (children && children.length) {
            let asyncChildren = filterAsyncRouter(children, accessibleRouteMap, `${basePath}/${elementPath}`);
            element.children = asyncChildren;
            matched = matched || asyncChildren.length > 0;
        }
        return matched;
    }).sort(naturalOrderComparator);
}

/**
 * @param left object with meta({sort: number}) attr
 * @param right object with meta({sort: number}) attr
 * @returns {number} compare result
 */
export function naturalOrderComparator(left, right) {
    let leftMeta = left.meta;
    let rightMeta = right.meta;
    if (!leftMeta || leftMeta.sort == null) {
        return rightMeta && rightMeta.sort != null ? 1 : 0;
    }
    if (!rightMeta || rightMeta.sort == null) {
        return -1;
    }
    return leftMeta.sort - rightMeta.sort;
}

/**
 * 因组件名称与其路由名称不一致会导致组件缓存失效，对每个组件进行检查，不一致则抛出异常。
 *
 * 应该仅在开发环境下调用
 */
export function checkComponentNameSameWithRoute(routers, excluder) {
    if (Array.isArray(routers) && routers.length > 0) {
        routers.forEach(router => {
            let {name, component, children, meta} = router;
            if (meta && meta.noCache) {
                return;
            }
            if (Array.isArray(children) && children.length > 0) {
                checkComponentNameSameWithRoute(children, excluder);
            }
            if (excluder && excluder(name, component)) {
                return;
            }

            if (isFunction(component)) {
                component().then(result => {
                    if (result && result.default) {
                        if (result.default.name !== name) {
                            throw new Error(`Component name must be same with Route name, router name: [${name}], component name: [${result.default.name}]`);
                        }
                    }
                });
            } else if (isObject(component)) {
                //ignore Layout
            }
        });
    }
}

/**
 * 合并两个路由表，同层级且相同path路由的children也会合并至第一个路由下的children列表中
 * @param target    目标路由数组
 * @param source    源路由数组
 * @returns target  目标路由数组
 */
export function mergeRoutes(target, source) {
    if (!Array.isArray(target) || !Array.isArray(source) || !source.length) {
        return target;
    }
    const sourceEntries = source.map(element => ([element.path, element]));
    const sourcePathMap = new Map(sourceEntries);

    if (target.length) {
        target.forEach(element => {
            const {path} = element;
            if (path && sourcePathMap.has(path)) {
                const {children} = sourcePathMap.get(path);
                if (children && children.length) {
                    const targetChildren = element.children || (element.children = []);
                    mergeRoutes(targetChildren, children);
                }
                sourcePathMap.delete(path);
            }
        });
    }

    if (sourcePathMap.size) {
        [...sourcePathMap.values()].forEach(route => target.push(route));
    }

    return target;
}
