import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/common.scss'
import '@/assets/style/index.scss' // global css
import '@/util/permission'
import '@/util/dialogDrag'

// 引入echarts
import * as echarts from 'echarts'
import bjggs from '@/util/bjggs'

import XSearchItem from '@/components/x/XSearchItem'
import XSelectorMany from '@/components/x/dict/XSelectorMany'
import XSelectorOne from '@/components/x/dict/XSelectorOne'
import XCheck from '@/components/x/dict/XCheck'
import XCheckGroup from '@/components/x/dict/XCheckGroup'
import XRadio from '@/components/x/dict/XRadio'
import XRadioGroup from '@/components/x/dict/XRadioGroup'
import XSwitch from '@/components/x/dict/XSwitch'
import XDictShow from '@/components/x/dict/XDictShow'
import XDetail from '@/components/x/detail/XDetail'
import XDetailItem from '@/components/x/detail/XDetailItem'
import XSelector from '@/components/x/selector/XSelector'
import XOrganSelector from '@/components/x/selector/XOrganSelector'
import XTreeSelector from '@/components/x/selector/XTreeSelector'
import XDivider from '@/components/x/XDivider'
import XDictCascader from '@/components/x/dict/XDictCascader'
import XDictCascaderShow from "@/components/x/dict/XDictCascaderShow"
import XRow from '@/components/x/row/XRow'
import XRowCell from '@/components/x/row/XRowCell'
import ExcelExport from "@/components/excel/ExcelExport";
import ExcelImport from "@/components/excel/ExcelImport";
import PrintableForm from "@/components/Print/PrintableForm";
import XDialog from '@/components/x/XDialog';
import XDateBetween from "@/components/x/XDateBetween";

Vue.prototype.$echarts = echarts


Vue.use(ElementUI);
Vue.component('ElDialog', XDialog);
Vue.component('XSearchItem', XSearchItem);
Vue.component('XSelectorMany', XSelectorMany);
Vue.component('XSelectorOne', XSelectorOne);
Vue.component('XCheck', XCheck);
Vue.component('XCheckGroup', XCheckGroup);
Vue.component('XRadio', XRadio);
Vue.component('XRadioGroup', XRadioGroup);
Vue.component('XSwitch', XSwitch);
Vue.component('XDictShow', XDictShow);
Vue.component('XDetail', XDetail);
Vue.component('XDetailItem', XDetailItem);
Vue.component('XSelector', XSelector);
Vue.component('XOrganSelector', XOrganSelector);
Vue.component('XTreeSelector', XTreeSelector);
Vue.component('XDivider', XDivider);
Vue.component('ExcelExport', ExcelExport);
Vue.component('ExcelImport', ExcelImport);
Vue.component('PrintButton', PrintableForm);
Vue.component('XDictCascader', XDictCascader);
Vue.component('XDictCascaderShow', XDictCascaderShow);
Vue.component('XDateBetween', XDateBetween);
Vue.component('XRow', XRow);
Vue.component('XRowCell', XRowCell);

Vue.prototype.$bjggs = bjggs;
Vue.prototype.$bus = new Vue();

Vue.prototype.$msgSuccess = function (msg) {
    this.$message({showClose: true, message: msg, type: "success"});
};

Vue.prototype.$msgError = function (msg) {
    this.$message({showClose: true, message: msg, type: "error"});
};

Vue.prototype.$msgInfo = function (msg) {
    this.$message.info(msg);
};

export default Vue;

